var __defProp = Object.defineProperty;
var __markAsModule = (target) => __defProp(target, "__esModule", { value: true });
var __export = (target, all) => {
  __markAsModule(target);
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
__export(exports, {
  definitionRawData: () => definitionRawData,
  idMaps: () => idMaps,
  pathRawData: () => pathRawData
});
const definitionRawData = {
  date: {
    type: "string",
    format: "date",
    example: "2018-01-01",
    properties: {}
  },
  datetime: {
    type: "string",
    format: "date-time",
    example: "2018-08-01T00:00:00.000Z",
    properties: {}
  },
  Pagination: {
    type: "object",
    properties: {
      currentPage: { type: "number" },
      nextPage: { type: "number", nullable: true },
      prevPage: { type: "number", nullable: true },
      totalPages: { type: "number" },
      totalCount: { type: "number" }
    }
  },
  stringArray: { type: "array", items: { type: "string" }, properties: {} },
  stringPagedArray: {
    type: "object",
    properties: {
      data: { type: "array", items: { type: "string" } },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  arrayNumber: { type: "array", items: { type: "number" }, properties: {} },
  pagedArrayNumber: {
    type: "object",
    properties: {
      data: { type: "array", items: { type: "number" } },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  BrandConnectionOption: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "品牌名称" }
    }
  },
  BrandConnectionDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "品牌名称" },
      mpUrl: { type: "string", description: "品牌ID" },
      brandId: { type: "number", description: "品牌" },
      position: { type: "number", description: "排序" }
    }
  },
  BrandMpTabOption: {
    type: "object",
    properties: { id: { type: "number", description: "ID" } }
  },
  BrandMpTabDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      title: { type: "string", description: "标题" },
      tabType: { type: "string", description: "产品类型" },
      viewType: { type: "string", description: "展示模式" },
      applyToAllProduct: { type: "boolean", description: "展示所有产品排课" },
      productIds: {
        type: "array",
        items: { type: "number" },
        description: "展示产品"
      },
      active: { type: "boolean", description: "激活" },
      position: { type: "number", description: "排序" }
    }
  },
  BrandConnectionDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "品牌名称" },
            mpUrl: { type: "string", description: "品牌ID" },
            brandId: { type: "number", description: "品牌" },
            position: { type: "number", description: "排序" }
          }
        },
        ref: "BrandConnectionDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandBrandConnectionCreateRequest: {
    type: "object",
    properties: {
      name: { type: "string", description: "品牌名称", required: true },
      mpUrl: { type: "string", description: "品牌ID", required: true },
      position: { type: "number", description: "排序" }
    }
  },
  V2BrandBrandConnectionUpdateRequest: {
    type: "object",
    properties: {
      name: { type: "string", description: "品牌名称" },
      mpUrl: { type: "string", description: "品牌ID" },
      brandId: { type: "number", description: "品牌" },
      position: { type: "number", description: "排序" }
    }
  },
  BrandFitnessTestMetricOption: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" }
    }
  },
  BrandFitnessTestMetricDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      label: { type: "string", description: "项目名称" },
      icon: { type: "string", description: "图标" },
      category: { type: "string", description: "类型" },
      unit: { type: "string", description: "单位" },
      position: { type: "number", description: "排序" },
      required: { type: "boolean", description: "必填" },
      active: { type: "boolean", description: "激活" }
    }
  },
  BrandFitnessTestMetricEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      label: { type: "string", description: "项目名称" },
      icon: { type: "string", description: "图标" },
      category: { type: "string", description: "类型" },
      unit: { type: "string", description: "单位" }
    }
  },
  BrandFitnessTestMetricOptionPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" }
          }
        },
        ref: "BrandFitnessTestMetricOption"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  BrandFitnessTestMetricDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            label: { type: "string", description: "项目名称" },
            icon: { type: "string", description: "图标" },
            category: { type: "string", description: "类型" },
            unit: { type: "string", description: "单位" },
            position: { type: "number", description: "排序" },
            required: { type: "boolean", description: "必填" },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "BrandFitnessTestMetricDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandBrandFitnessTestMetricCreateRequest: {
    type: "object",
    properties: {
      name: { type: "string", description: "名称", required: true },
      label: { type: "string", description: "项目名称", required: true },
      category: { type: "string", description: "类型", required: true },
      unit: { type: "string", description: "单位", required: true },
      position: { type: "number", description: "排序", required: true },
      active: { type: "boolean", description: "激活", required: true }
    }
  },
  V2BrandBrandFitnessTestMetricUpdateRequest: {
    type: "object",
    properties: {
      active: { type: "boolean", description: "激活" },
      required: { type: "boolean", description: "必填" },
      position: { type: "number", description: "排序" }
    }
  },
  BrandMpTabDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            title: { type: "string", description: "标题" },
            tabType: { type: "string", description: "产品类型" },
            viewType: { type: "string", description: "展示模式" },
            applyToAllProduct: {
              type: "boolean",
              description: "展示所有产品排课"
            },
            productIds: {
              type: "array",
              items: { type: "number" },
              description: "展示产品"
            },
            active: { type: "boolean", description: "激活" },
            position: { type: "number", description: "排序" }
          }
        },
        ref: "BrandMpTabDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandBrandMpTabCreateRequest: {
    type: "object",
    properties: {
      title: { type: "string", description: "标题", required: true },
      viewType: {
        type: "string",
        description: "展示模式",
        enum: ["calendar", "list"],
        "x-i18n": { calendar: "日历", list: "列表" },
        required: true
      },
      applyToAllProduct: {
        type: "boolean",
        description: "展示所有产品排课",
        required: true
      },
      active: { type: "boolean", description: "激活", required: true },
      position: { type: "number", description: "排序", required: true },
      productIds: { type: "array", description: "展示产品" }
    }
  },
  V2BrandBrandMpTabUpdateRequest: {
    type: "object",
    properties: {
      title: { type: "string", description: "标题" },
      viewType: {
        type: "string",
        description: "展示模式",
        enum: ["calendar", "list"],
        "x-i18n": { calendar: "日历", list: "列表" }
      },
      active: { type: "boolean", description: "激活" },
      position: { type: "number", description: "排序" },
      applyToAllProduct: { type: "boolean", description: "展示所有产品排课" },
      productIds: { type: "array", description: "展示产品" }
    }
  },
  V2CoachCoachOption: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" }
    }
  },
  QuickstepsSerializerAttachmentDefault: {
    type: "object",
    properties: {
      uid: { type: "number" },
      signedId: { type: "string" },
      name: { type: "string" },
      url: { type: "string" },
      contentType: { type: "string" }
    }
  },
  V2AttachmentDefault: {
    type: "object",
    properties: {
      uid: { type: "number", description: "Uid TODO" },
      signedId: { type: "string", description: "Signed TODO" },
      name: { type: "string", description: "Name TODO" },
      url: { type: "string", description: "Url TODO" },
      originalUrl: { type: "string", description: "Original url TODO" },
      contentType: { type: "string", description: "Content type TODO" }
    }
  },
  V2CoachCoachDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      displayName: { type: "string", description: "Display name TODO" },
      avatar: {
        description: "Avatar TODO",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      }
    }
  },
  V2StudioStudioOption: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      fullName: { type: "string", description: "Full name TODO" },
      shortName: { type: "string", description: "Short name TODO" }
    }
  },
  V2StudioStudioEssential: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      fullName: { type: "string", description: "Full name TODO" },
      shortName: { type: "string", description: "Short name TODO" },
      active: { type: "boolean", description: "Active TODO" },
      eliteTraining: { type: "boolean", description: "Elite training TODO" },
      display: { type: "boolean", description: "Display TODO" },
      address: { type: "string", description: "Address TODO" },
      contactPhone: { type: "string", description: "Contact phone TODO" },
      longitude: { type: "string", description: "Longitude TODO" },
      latitude: { type: "string", description: "Latitude TODO" },
      gatePassword: { type: "string", description: "Gate password TODO" }
    }
  },
  V2StudioStudioDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      fullName: { type: "string", description: "Full name TODO" },
      shortName: { type: "string", description: "Short name TODO" },
      active: { type: "boolean", description: "Active TODO" },
      eliteTraining: { type: "boolean", description: "Elite training TODO" },
      display: { type: "boolean", description: "Display TODO" },
      address: { type: "string", description: "Address TODO" },
      contactPhone: { type: "string", description: "Contact phone TODO" },
      longitude: { type: "string", description: "Longitude TODO" },
      latitude: { type: "string", description: "Latitude TODO" },
      gatePassword: { type: "string", description: "Gate password TODO" },
      businessOpenTime: {
        type: "string",
        description: "Business open time TODO"
      },
      businessCloseTime: {
        type: "string",
        description: "Business close time TODO"
      },
      traffic: { type: "string", nullable: true, description: "Traffic TODO" },
      gateMacs: {
        type: "array",
        items: { type: "string" },
        description: "Gate macs TODO"
      },
      checkInQrCode: {
        description: "Check in qr code TODO",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      },
      openGateQrCodes: {
        description: "Open gate qr codes TODO",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      },
      primaryImage: {
        description: "Primary image TODO",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      },
      images: {
        description: "Images TODO",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      },
      checkInUrl: { type: "string", description: "Check in url TODO" },
      adsUrl: { type: "string", description: "Ads url TODO" },
      gateStatus: {
        type: "array",
        items: { type: "boolean" },
        description: "Gate status TODO"
      }
    }
  },
  V2StudioZoneOption: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      fullName: { type: "string", description: "Full name TODO" },
      eliteTraining: { type: "boolean", description: "Elite training TODO" },
      studio: {
        description: "Studio TODO",
        ref: "V2StudioStudioOption",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          fullName: { type: "string", description: "Full name TODO" },
          shortName: { type: "string", description: "Short name TODO" }
        }
      }
    }
  },
  V2BrandCourseCourseDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      startTime: {
        description: "Start time TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      name: { type: "string", description: "Name TODO" },
      enName: { type: "string", description: "En name TODO" },
      homework: { type: "string", description: "Homework TODO" },
      primaryCoach: {
        description: "Primary coach TODO",
        ref: "V2CoachCoachDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          displayName: { type: "string", description: "Display name TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      assistantCoaches: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            name: { type: "string", description: "Name TODO" },
            displayName: { type: "string", description: "Display name TODO" },
            avatar: {
              description: "Avatar TODO",
              ref: "V2AttachmentDefault",
              type: "object",
              properties: {
                uid: { type: "number", description: "Uid TODO" },
                signedId: { type: "string", description: "Signed TODO" },
                name: { type: "string", description: "Name TODO" },
                url: { type: "string", description: "Url TODO" },
                originalUrl: {
                  type: "string",
                  description: "Original url TODO"
                },
                contentType: {
                  type: "string",
                  description: "Content type TODO"
                }
              }
            }
          }
        },
        description: "Assistant coaches TODO",
        ref: "V2CoachCoachDefault"
      },
      zone: {
        description: "Zone TODO",
        ref: "V2StudioZoneOption",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          fullName: { type: "string", description: "Full name TODO" },
          eliteTraining: {
            type: "boolean",
            description: "Elite training TODO"
          },
          studio: {
            description: "Studio TODO",
            ref: "V2StudioStudioOption",
            type: "object",
            properties: {
              id: { type: "number", description: "Id TODO" },
              name: { type: "string", description: "Name TODO" },
              fullName: { type: "string", description: "Full name TODO" },
              shortName: { type: "string", description: "Short name TODO" }
            }
          }
        }
      }
    }
  },
  V2BrandCourseCourseCancellation: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      startTime: {
        description: "Start time TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      name: { type: "string", description: "Name TODO" },
      enName: { type: "string", description: "En name TODO" },
      homework: { type: "string", description: "Homework TODO" },
      product: { version: "list", description: "Product TODO" },
      primaryCoach: { description: "Primary coach TODO" },
      assistantCoaches: { description: "Assistant coaches TODO" },
      zone: { description: "Zone TODO" },
      cancellationType: {
        type: "string",
        description: "Cancellation type TODO"
      },
      actionNotes: { type: "string", description: "Action notes TODO" }
    }
  },
  V2BrandCourseCourseCancellationPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            startTime: {
              description: "Start time TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            name: { type: "string", description: "Name TODO" },
            enName: { type: "string", description: "En name TODO" },
            homework: { type: "string", description: "Homework TODO" },
            product: { version: "list", description: "Product TODO" },
            primaryCoach: { description: "Primary coach TODO" },
            assistantCoaches: { description: "Assistant coaches TODO" },
            zone: { description: "Zone TODO" },
            cancellationType: {
              type: "string",
              description: "Cancellation type TODO"
            },
            actionNotes: { type: "string", description: "Action notes TODO" }
          }
        },
        ref: "V2BrandCourseCourseCancellation"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandEliteMemberBase: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "昵称" },
      realName: { type: "string", description: "真实姓名" },
      phone: { type: "string", description: "电话" },
      gender: { type: "string", description: "性别" },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      coach: {
        description: "Coach TODO",
        ref: "V2CoachCoachDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          displayName: { type: "string", description: "Display name TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      avatar: {
        description: "头像",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      }
    }
  },
  V2BrandEliteMemberActive: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "昵称" },
      realName: { type: "string", description: "真实姓名" },
      phone: { type: "string", description: "电话" },
      gender: { type: "string", description: "性别" },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      coach: { description: "Coach TODO" },
      avatar: { description: "头像" },
      courseCount: { type: "number", description: "私教上课次数" },
      totalBalance: { type: "number", description: "私教卡剩余总次数" }
    }
  },
  V2BrandEliteMemberInactive: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "昵称" },
      realName: { type: "string", description: "真实姓名" },
      phone: { type: "string", description: "电话" },
      gender: { type: "string", description: "性别" },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      coach: { description: "Coach TODO" },
      avatar: { description: "头像" },
      lastCourseTime: {
        description: "上次私教上课时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      totalBalance: { type: "number", description: "私教卡剩余总次数" }
    }
  },
  V2BrandEliteMemberChruned: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "昵称" },
      realName: { type: "string", description: "真实姓名" },
      phone: { type: "string", description: "电话" },
      gender: { type: "string", description: "性别" },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      coach: { description: "Coach TODO" },
      avatar: { description: "头像" },
      courseCount: { type: "number", description: "私教上课次数" },
      totalBalance: { type: "number", description: "私教卡剩余总次数" }
    }
  },
  V2BrandEliteMemberAll: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "昵称" },
      realName: { type: "string", description: "真实姓名" },
      phone: { type: "string", description: "电话" },
      gender: { type: "string", description: "性别" },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      coach: { description: "Coach TODO" },
      avatar: { description: "头像" },
      courseCount: { type: "number", description: "私教上课次数" },
      totalBalance: { type: "number", description: "私教卡剩余总次数" }
    }
  },
  V2BrandEliteMemberUnassigned: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "昵称" },
      realName: { type: "string", description: "真实姓名" },
      phone: { type: "string", description: "电话" },
      gender: { type: "string", description: "性别" },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "标签"
      },
      coach: { description: "Coach TODO" },
      avatar: { description: "头像" },
      courseCount: { type: "number", description: "私教上课次数" },
      totalBalance: { type: "number", description: "私教卡剩余总次数" }
    }
  },
  V2BrandEliteMemberActivePagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "昵称" },
            realName: { type: "string", description: "真实姓名" },
            phone: { type: "string", description: "电话" },
            gender: { type: "string", description: "性别" },
            tagList: {
              type: "array",
              items: { type: "string" },
              description: "标签"
            },
            coach: { description: "Coach TODO" },
            avatar: { description: "头像" },
            courseCount: { type: "number", description: "私教上课次数" },
            totalBalance: { type: "number", description: "私教卡剩余总次数" }
          }
        },
        ref: "V2BrandEliteMemberActive"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandEliteMemberChrunedPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "昵称" },
            realName: { type: "string", description: "真实姓名" },
            phone: { type: "string", description: "电话" },
            gender: { type: "string", description: "性别" },
            tagList: {
              type: "array",
              items: { type: "string" },
              description: "标签"
            },
            coach: { description: "Coach TODO" },
            avatar: { description: "头像" },
            courseCount: { type: "number", description: "私教上课次数" },
            totalBalance: { type: "number", description: "私教卡剩余总次数" }
          }
        },
        ref: "V2BrandEliteMemberChruned"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandEliteMemberAllPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "昵称" },
            realName: { type: "string", description: "真实姓名" },
            phone: { type: "string", description: "电话" },
            gender: { type: "string", description: "性别" },
            tagList: {
              type: "array",
              items: { type: "string" },
              description: "标签"
            },
            coach: { description: "Coach TODO" },
            avatar: { description: "头像" },
            courseCount: { type: "number", description: "私教上课次数" },
            totalBalance: { type: "number", description: "私教卡剩余总次数" }
          }
        },
        ref: "V2BrandEliteMemberAll"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandEliteMemberUnassignedPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "昵称" },
            realName: { type: "string", description: "真实姓名" },
            phone: { type: "string", description: "电话" },
            gender: { type: "string", description: "性别" },
            tagList: {
              type: "array",
              items: { type: "string" },
              description: "标签"
            },
            coach: { description: "Coach TODO" },
            avatar: { description: "头像" },
            courseCount: { type: "number", description: "私教上课次数" },
            totalBalance: { type: "number", description: "私教卡剩余总次数" }
          }
        },
        ref: "V2BrandEliteMemberUnassigned"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2FitnessTestRecordOption: {
    type: "object",
    properties: { id: { type: "number", description: "Id TODO" } }
  },
  V2FitnessTestRecordDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      memberId: { type: "number", description: "Member TODO" },
      coach: {
        description: "Coach TODO",
        ref: "V2CoachCoachDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          displayName: { type: "string", description: "Display name TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      date: {
        description: "Date TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      height: { type: "string", description: "Height TODO" },
      age: { type: "number", description: "Age TODO" },
      weight: { type: "string", description: "Weight TODO" },
      bmi: { type: "string", description: "Bmi TODO" }
    }
  },
  V2FitnessTestItemStateOption: {
    type: "object",
    properties: { id: { type: "number", description: "Id TODO" } }
  },
  V2FitnessTestItemStateDefault: {
    type: "object",
    properties: {
      metric: {
        description: "Metric TODO",
        ref: "BrandFitnessTestMetricEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          label: { type: "string", description: "项目名称" },
          icon: { type: "string", description: "图标" },
          category: { type: "string", description: "类型" },
          unit: { type: "string", description: "单位" }
        }
      },
      value: { type: "string", description: "Value TODO" },
      trend: { type: "string", description: "Trend TODO" },
      beforeImage: {
        description: "Before image TODO",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      },
      afterImage: {
        description: "After image TODO",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      }
    }
  },
  V2FitnessTestRecordStatistic: {
    type: "object",
    properties: {
      items: {
        type: "array",
        items: {
          type: "object",
          properties: {
            metric: {
              description: "Metric TODO",
              ref: "BrandFitnessTestMetricEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                label: { type: "string", description: "项目名称" },
                icon: { type: "string", description: "图标" },
                category: { type: "string", description: "类型" },
                unit: { type: "string", description: "单位" }
              }
            },
            value: { type: "string", description: "Value TODO" },
            trend: { type: "string", description: "Trend TODO" },
            beforeImage: {
              description: "Before image TODO",
              ref: "V2AttachmentDefault",
              type: "object",
              properties: {
                uid: { type: "number", description: "Uid TODO" },
                signedId: { type: "string", description: "Signed TODO" },
                name: { type: "string", description: "Name TODO" },
                url: { type: "string", description: "Url TODO" },
                originalUrl: {
                  type: "string",
                  description: "Original url TODO"
                },
                contentType: {
                  type: "string",
                  description: "Content type TODO"
                }
              }
            },
            afterImage: {
              description: "After image TODO",
              ref: "V2AttachmentDefault",
              type: "object",
              properties: {
                uid: { type: "number", description: "Uid TODO" },
                signedId: { type: "string", description: "Signed TODO" },
                name: { type: "string", description: "Name TODO" },
                url: { type: "string", description: "Url TODO" },
                originalUrl: {
                  type: "string",
                  description: "Original url TODO"
                },
                contentType: {
                  type: "string",
                  description: "Content type TODO"
                }
              }
            }
          }
        },
        description: "Items TODO",
        ref: "V2FitnessTestItemStateDefault"
      },
      recordsCount: { type: "number", description: "Records count TODO" },
      startDate: {
        description: "Start date TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      endDate: {
        description: "End date TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      days: { type: "number", description: "Days TODO" }
    }
  },
  V2FitnessTestItemOption: {
    type: "object",
    properties: { id: { type: "number", description: "Id TODO" } }
  },
  V2FitnessTestItemDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      metric: {
        description: "Metric TODO",
        ref: "BrandFitnessTestMetricEssential",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "名称" },
          label: { type: "string", description: "项目名称" },
          icon: { type: "string", description: "图标" },
          category: { type: "string", description: "类型" },
          unit: { type: "string", description: "单位" }
        }
      },
      recordId: { type: "number", description: "Record TODO" },
      value: { type: "string", description: "Value TODO" },
      image: {
        description: "Image TODO",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      },
      position: { type: "number", description: "Position TODO" }
    }
  },
  V2FitnessTestRecordDetail: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      memberId: { type: "number", description: "Member TODO" },
      coach: {
        description: "Coach TODO",
        ref: "V2CoachCoachDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          displayName: { type: "string", description: "Display name TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      date: {
        description: "Date TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      height: { type: "string", description: "Height TODO" },
      age: { type: "number", description: "Age TODO" },
      items: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            metric: {
              description: "Metric TODO",
              ref: "BrandFitnessTestMetricEssential",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "名称" },
                label: { type: "string", description: "项目名称" },
                icon: { type: "string", description: "图标" },
                category: { type: "string", description: "类型" },
                unit: { type: "string", description: "单位" }
              }
            },
            recordId: { type: "number", description: "Record TODO" },
            value: { type: "string", description: "Value TODO" },
            image: {
              description: "Image TODO",
              ref: "V2AttachmentDefault",
              type: "object",
              properties: {
                uid: { type: "number", description: "Uid TODO" },
                signedId: { type: "string", description: "Signed TODO" },
                name: { type: "string", description: "Name TODO" },
                url: { type: "string", description: "Url TODO" },
                originalUrl: {
                  type: "string",
                  description: "Original url TODO"
                },
                contentType: {
                  type: "string",
                  description: "Content type TODO"
                }
              }
            },
            position: { type: "number", description: "Position TODO" }
          }
        },
        description: "Items TODO",
        ref: "V2FitnessTestItemDefault"
      }
    }
  },
  V2FitnessTestRecordDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            memberId: { type: "number", description: "Member TODO" },
            coach: {
              description: "Coach TODO",
              ref: "V2CoachCoachDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "Id TODO" },
                name: { type: "string", description: "Name TODO" },
                displayName: {
                  type: "string",
                  description: "Display name TODO"
                },
                avatar: {
                  description: "Avatar TODO",
                  ref: "V2AttachmentDefault",
                  type: "object",
                  properties: {
                    uid: { type: "number", description: "Uid TODO" },
                    signedId: { type: "string", description: "Signed TODO" },
                    name: { type: "string", description: "Name TODO" },
                    url: { type: "string", description: "Url TODO" },
                    originalUrl: {
                      type: "string",
                      description: "Original url TODO"
                    },
                    contentType: {
                      type: "string",
                      description: "Content type TODO"
                    }
                  }
                }
              }
            },
            date: {
              description: "Date TODO",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            height: { type: "string", description: "Height TODO" },
            age: { type: "number", description: "Age TODO" },
            weight: { type: "string", description: "Weight TODO" },
            bmi: { type: "string", description: "Bmi TODO" }
          }
        },
        ref: "V2FitnessTestRecordDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandFitnessTestRecordCreateRequest: {
    type: "object",
    properties: {
      memberId: { type: "number", description: "Member TODO", required: true },
      date: {
        type: "string",
        format: "date",
        description: "Date TODO",
        required: true
      },
      data: { type: "array", description: "Data TODO", required: true }
    }
  },
  V2MemberCoachRecordDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      memberId: { type: "number", description: "Member TODO" },
      coach: {
        description: "Coach TODO",
        ref: "V2CoachCoachDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          displayName: { type: "string", description: "Display name TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      createdAt: {
        description: "Created at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  V2MemberCoachRecordDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            memberId: { type: "number", description: "Member TODO" },
            coach: {
              description: "Coach TODO",
              ref: "V2CoachCoachDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "Id TODO" },
                name: { type: "string", description: "Name TODO" },
                displayName: {
                  type: "string",
                  description: "Display name TODO"
                },
                avatar: {
                  description: "Avatar TODO",
                  ref: "V2AttachmentDefault",
                  type: "object",
                  properties: {
                    uid: { type: "number", description: "Uid TODO" },
                    signedId: { type: "string", description: "Signed TODO" },
                    name: { type: "string", description: "Name TODO" },
                    url: { type: "string", description: "Url TODO" },
                    originalUrl: {
                      type: "string",
                      description: "Original url TODO"
                    },
                    contentType: {
                      type: "string",
                      description: "Content type TODO"
                    }
                  }
                }
              }
            },
            createdAt: {
              description: "Created at TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        ref: "V2MemberCoachRecordDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandMembershipAccountOption: {
    type: "object",
    properties: { id: { type: "number", description: "ID" } }
  },
  V2MemberMemberOption: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      phone: { type: "string", description: "Phone TODO" },
      avatar: {
        description: "Avatar TODO",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      }
    }
  },
  V2MemberMemberDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      brandId: { type: "number", description: "Brand TODO" },
      userId: { type: "number", description: "User TODO" },
      name: { type: "string", description: "Name TODO" },
      gender: { type: "string", description: "Gender TODO" },
      dateOfBirth: {
        description: "Date of birth TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      weight: { type: "number", description: "Weight TODO" },
      promoCode: { type: "string", description: "Promo code TODO" },
      active: { type: "boolean", description: "Active TODO" },
      bookingUpdatedAt: {
        description: "Booking updated at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      realName: { type: "string", description: "Real name TODO" },
      maxHr: { type: "number", description: "Max hr TODO" },
      introduction: { type: "string", description: "Introduction TODO" },
      displayProfilePhoto: {
        type: "boolean",
        description: "Display profile photo TODO"
      },
      historyTrainingCount: {
        type: "number",
        description: "History training count TODO"
      },
      vip: { type: "boolean", description: "Vip TODO" },
      defaultStudioId: { type: "number", description: "Default studio TODO" },
      firstTrainingAt: {
        description: "First training at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      lastTrainingAt: {
        description: "Last training at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      parentId: { type: "number", description: "Parent TODO" },
      prospect: { type: "boolean", description: "Prospect TODO" },
      joinedHofAt: {
        description: "Joined hof at TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      hofNotes: { type: "string", description: "Hof notes TODO" }
    }
  },
  V2MemberMemberFitnessTest: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      realName: { type: "string", description: "Real name TODO" },
      gender: { type: "string", description: "Gender TODO" },
      height: { type: "number", description: "Height TODO" },
      weight: { type: "number", description: "Weight TODO" },
      dateOfBirth: {
        description: "Date of birth TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      age: { type: "number", description: "Age TODO" },
      avatar: {
        description: "Avatar TODO",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      }
    }
  },
  V2BrandMembershipAccountDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      member: {
        description: "会员",
        ref: "V2MemberMemberOption",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          phone: { type: "string", description: "Phone TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      value: { type: "string", description: "真实余额" },
      bonus: { type: "string", description: "赠送余额" },
      balance: { type: "string", description: "余额" },
      settlementPrice: { type: "string", description: "结算单价" },
      settlementAmount: { type: "string", description: "结算金额" }
    }
  },
  V2BrandMembershipAccountOperating: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      member: {
        description: "会员",
        ref: "V2MemberMemberOption",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          phone: { type: "string", description: "Phone TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      value: { type: "string", description: "真实余额" },
      bonus: { type: "string", description: "赠送余额" },
      balance: { type: "string", description: "余额" },
      settlementPrice: { type: "string", description: "结算单价" },
      settlementAmount: { type: "string", description: "结算金额" },
      accountType: { type: "string", description: "Account type TODO" },
      validityPeriod: { type: "number", description: "有效期" },
      activateAt: { type: "object", description: "Activate at TODO" },
      expiryDate: {
        description: "有效日期",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      workflowState: { type: "string", description: "状态" }
    }
  },
  MembershipAccountLogDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      actor: { type: "string", description: "操作人" },
      details: { type: "string", description: "操作内容" },
      action: {
        type: "string",
        enum: [
          "pause",
          "extend_validity_period",
          "resume",
          "activate",
          "update_coaches"
        ],
        description: "操作",
        "x-i18n": {
          pause: "账户停卡",
          extend_validity_period: "账户延期",
          resume: "账户恢复",
          activate: "账户开卡",
          update_coaches: "更改授课教练"
        }
      },
      account: {
        description: "账户",
        ref: "V2BrandMembershipAccountDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          member: {
            description: "会员",
            ref: "V2MemberMemberOption",
            type: "object",
            properties: {
              id: { type: "number", description: "Id TODO" },
              name: { type: "string", description: "Name TODO" },
              phone: { type: "string", description: "Phone TODO" },
              avatar: {
                description: "Avatar TODO",
                ref: "V2AttachmentDefault",
                type: "object",
                properties: {
                  uid: { type: "number", description: "Uid TODO" },
                  signedId: { type: "string", description: "Signed TODO" },
                  name: { type: "string", description: "Name TODO" },
                  url: { type: "string", description: "Url TODO" },
                  originalUrl: {
                    type: "string",
                    description: "Original url TODO"
                  },
                  contentType: {
                    type: "string",
                    description: "Content type TODO"
                  }
                }
              }
            }
          },
          value: { type: "string", description: "真实余额" },
          bonus: { type: "string", description: "赠送余额" },
          balance: { type: "string", description: "余额" },
          settlementPrice: { type: "string", description: "结算单价" },
          settlementAmount: { type: "string", description: "结算金额" }
        }
      },
      createdAt: {
        description: "操作时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  MembershipAccountLogDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            actor: { type: "string", description: "操作人" },
            details: { type: "string", description: "操作内容" },
            action: {
              type: "string",
              enum: [
                "pause",
                "extend_validity_period",
                "resume",
                "activate",
                "update_coaches"
              ],
              description: "操作",
              "x-i18n": {
                pause: "账户停卡",
                extend_validity_period: "账户延期",
                resume: "账户恢复",
                activate: "账户开卡",
                update_coaches: "更改授课教练"
              }
            },
            account: {
              description: "账户",
              ref: "V2BrandMembershipAccountDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                member: {
                  description: "会员",
                  ref: "V2MemberMemberOption",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "Id TODO" },
                    name: { type: "string", description: "Name TODO" },
                    phone: { type: "string", description: "Phone TODO" },
                    avatar: {
                      description: "Avatar TODO",
                      ref: "V2AttachmentDefault",
                      type: "object",
                      properties: {
                        uid: { type: "number", description: "Uid TODO" },
                        signedId: {
                          type: "string",
                          description: "Signed TODO"
                        },
                        name: { type: "string", description: "Name TODO" },
                        url: { type: "string", description: "Url TODO" },
                        originalUrl: {
                          type: "string",
                          description: "Original url TODO"
                        },
                        contentType: {
                          type: "string",
                          description: "Content type TODO"
                        }
                      }
                    }
                  }
                },
                value: { type: "string", description: "真实余额" },
                bonus: { type: "string", description: "赠送余额" },
                balance: { type: "string", description: "余额" },
                settlementPrice: { type: "string", description: "结算单价" },
                settlementAmount: { type: "string", description: "结算金额" }
              }
            },
            createdAt: {
              description: "操作时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        ref: "MembershipAccountLogDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  BrandAdminNotificationDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      title: { type: "string", description: "标题" },
      content: { type: "string", description: "内容" },
      forceRead: { type: "boolean", description: "弹窗" },
      createdAt: {
        description: "发布时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      read: { type: "boolean", description: "已读" }
    }
  },
  BrandAdminNotificationInbox: {
    type: "object",
    properties: {
      unreadCount: { type: "number", description: "未读数" },
      readList: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            title: { type: "string", description: "标题" },
            content: { type: "string", description: "内容" },
            forceRead: { type: "boolean", description: "弹窗" },
            createdAt: {
              description: "发布时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            read: { type: "boolean", description: "已读" }
          }
        },
        description: "弹窗列表",
        ref: "BrandAdminNotificationDefault"
      }
    }
  },
  BrandAdminNotificationDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            title: { type: "string", description: "标题" },
            content: { type: "string", description: "内容" },
            forceRead: { type: "boolean", description: "弹窗" },
            createdAt: {
              description: "发布时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            read: { type: "boolean", description: "已读" }
          }
        },
        ref: "BrandAdminNotificationDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandMembershipAccountOperatingPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            member: {
              description: "会员",
              ref: "V2MemberMemberOption",
              type: "object",
              properties: {
                id: { type: "number", description: "Id TODO" },
                name: { type: "string", description: "Name TODO" },
                phone: { type: "string", description: "Phone TODO" },
                avatar: {
                  description: "Avatar TODO",
                  ref: "V2AttachmentDefault",
                  type: "object",
                  properties: {
                    uid: { type: "number", description: "Uid TODO" },
                    signedId: { type: "string", description: "Signed TODO" },
                    name: { type: "string", description: "Name TODO" },
                    url: { type: "string", description: "Url TODO" },
                    originalUrl: {
                      type: "string",
                      description: "Original url TODO"
                    },
                    contentType: {
                      type: "string",
                      description: "Content type TODO"
                    }
                  }
                }
              }
            },
            value: { type: "string", description: "真实余额" },
            bonus: { type: "string", description: "赠送余额" },
            balance: { type: "string", description: "余额" },
            settlementPrice: { type: "string", description: "结算单价" },
            settlementAmount: { type: "string", description: "结算金额" },
            accountType: { type: "string", description: "Account type TODO" },
            validityPeriod: { type: "number", description: "有效期" },
            activateAt: { type: "object", description: "Activate at TODO" },
            expiryDate: {
              description: "有效日期",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            workflowState: { type: "string", description: "状态" }
          }
        },
        ref: "V2BrandMembershipAccountOperating"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandOperatingAccountQueryAccountsRequest: {
    type: "object",
    properties: {
      includeThePunished: { type: "boolean", description: "是否包含惩罚账户" }
    }
  },
  V2BrandOperatingAccountPatchActivateRequest: {
    type: "object",
    properties: {
      includeThePunished: { type: "boolean", description: "是否包含惩罚账户" },
      timing: {
        type: "string",
        description: "设置激活方式",
        enum: ["now", "later"],
        "x-i18n": { now: "Now TODO", later: "Later TODO" },
        required: true
      },
      activateAt: {
        type: "string",
        format: "date-time",
        description: "激活时间"
      }
    }
  },
  V2BrandOperatingAccountPatchExtendValidityPeriodRequest: {
    type: "object",
    properties: {
      includeThePunished: { type: "boolean", description: "是否包含惩罚账户" },
      validityPeriod: { type: "number", description: "有效期", required: true },
      notes: { type: "string", description: "备注", required: true }
    }
  },
  V2BrandOperatingAccountPatchPauseRequest: {
    type: "object",
    properties: {
      includeThePunished: { type: "boolean", description: "是否包含惩罚账户" },
      dateType: {
        type: "string",
        description: "停卡时间",
        enum: ["days", "specfic_date"],
        "x-i18n": { days: "指定天数", specfic_date: "停卡至特定日期" },
        required: true
      },
      duration: { type: "number", description: "停卡天数" },
      resumeDate: {
        type: "string",
        format: "date",
        description: "停卡至特定日期"
      },
      notes: { type: "string", description: "备注" }
    }
  },
  V2BrandOperatingAccountPatchChangeCoachRequest: {
    type: "object",
    properties: {
      includeThePunished: { type: "boolean", description: "是否包含惩罚账户" },
      coachIds: { type: "array", description: "私教教练", required: true }
    }
  },
  V2BrandOperatingPrepayPurchaseDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      accountId: { type: "number", description: "Account TODO" },
      memberId: { type: "number", description: "Member TODO" },
      member: {
        description: "Member TODO",
        ref: "V2MemberMemberOption",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          phone: { type: "string", description: "Phone TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      recordType: { type: "string", description: "Record type TODO" },
      productDescription: {
        type: "string",
        description: "Product description TODO"
      },
      productPrice: { type: "number", description: "Product price TODO" },
      amount: { type: "number", description: "Amount TODO" },
      value: { type: "number", description: "Value TODO" },
      bonus: { type: "number", description: "Bonus TODO" },
      createdAt: {
        description: "Created at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  V2BrandOperatingPrepayPurchaseDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            accountId: { type: "number", description: "Account TODO" },
            memberId: { type: "number", description: "Member TODO" },
            member: {
              description: "Member TODO",
              ref: "V2MemberMemberOption",
              type: "object",
              properties: {
                id: { type: "number", description: "Id TODO" },
                name: { type: "string", description: "Name TODO" },
                phone: { type: "string", description: "Phone TODO" },
                avatar: {
                  description: "Avatar TODO",
                  ref: "V2AttachmentDefault",
                  type: "object",
                  properties: {
                    uid: { type: "number", description: "Uid TODO" },
                    signedId: { type: "string", description: "Signed TODO" },
                    name: { type: "string", description: "Name TODO" },
                    url: { type: "string", description: "Url TODO" },
                    originalUrl: {
                      type: "string",
                      description: "Original url TODO"
                    },
                    contentType: {
                      type: "string",
                      description: "Content type TODO"
                    }
                  }
                }
              }
            },
            recordType: { type: "string", description: "Record type TODO" },
            productDescription: {
              type: "string",
              description: "Product description TODO"
            },
            productPrice: { type: "number", description: "Product price TODO" },
            amount: { type: "number", description: "Amount TODO" },
            value: { type: "number", description: "Value TODO" },
            bonus: { type: "number", description: "Bonus TODO" },
            createdAt: {
              description: "Created at TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        ref: "V2BrandOperatingPrepayPurchaseDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandOrderCreateRequest: {
    type: "object",
    properties: {
      memberId: { type: "number", description: "会员", required: true },
      orderType: { type: "string", description: "订单类型", required: true },
      paymentMethod: {
        type: "string",
        description: "支付方式",
        required: true
      },
      cardId: { type: "number", description: "会员卡" },
      productId: { type: "number", description: "商品" },
      value: { type: "string", description: "充值金额" },
      bonus: { type: "string", description: "赠送金额" },
      validityPeriod: { type: "number", description: "有效期" },
      coachIds: { type: "array", description: "关联员工" },
      eliteTrainingCoachIds: { type: "array", description: "授课教练" },
      paymentAmount: { type: "string", description: "支付金额" },
      externalTransactionId: { type: "string", description: "商户交易ID" },
      notes: { type: "string", description: "备注" }
    }
  },
  PartnerConsumptionRecordOption: {
    type: "object",
    properties: { id: { type: "number", description: "Id TODO" } }
  },
  PartnerOption: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "商户名称" },
      discount: { type: "string", description: "储值折扣" }
    }
  },
  PartnerDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "商户名称" },
      brandId: { type: "number", description: "Brand TODO" },
      discount: { type: "string", description: "储值折扣" },
      discountNote: { type: "string", description: "储值折扣说明" },
      logo: {
        description: "Logo",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      },
      productImage: {
        description: "产品介绍图片",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      },
      active: { type: "boolean", description: "激活" }
    }
  },
  PartnerConsumptionRecordDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      serial: { type: "string", description: "Serial TODO" },
      partner: {
        description: "Partner TODO",
        ref: "PartnerOption",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "商户名称" },
          discount: { type: "string", description: "储值折扣" }
        }
      },
      member: {
        description: "Member TODO",
        ref: "V2MemberMemberOption",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          phone: { type: "string", description: "Phone TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      membershipRecordId: {
        type: "number",
        description: "Membership record TODO"
      },
      amount: { type: "string", description: "支付金额" },
      originalAmount: { type: "string", description: "原价" },
      discount: { type: "string", description: "Discount TODO" },
      products: { type: "string", description: "Products TODO" },
      actor: { type: "string", description: "Actor TODO" },
      note: { type: "string", description: "备注" }
    }
  },
  PartnerConsumptionRecordFinance: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      serial: { type: "string", description: "Serial TODO" },
      partner: { description: "Partner TODO" },
      member: { description: "Member TODO" },
      membershipRecordId: {
        type: "number",
        description: "Membership record TODO"
      },
      amount: { type: "string", description: "支付金额" },
      originalAmount: { type: "string", description: "原价" },
      discount: { type: "string", description: "Discount TODO" },
      products: { type: "string", description: "Products TODO" },
      actor: { type: "string", description: "Actor TODO" },
      note: { type: "string", description: "备注" },
      settlementAmount: {
        type: "string",
        description: "Settlement amount TODO"
      }
    }
  },
  PartnerConsumptionRecordDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            serial: { type: "string", description: "Serial TODO" },
            partner: {
              description: "Partner TODO",
              ref: "PartnerOption",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "商户名称" },
                discount: { type: "string", description: "储值折扣" }
              }
            },
            member: {
              description: "Member TODO",
              ref: "V2MemberMemberOption",
              type: "object",
              properties: {
                id: { type: "number", description: "Id TODO" },
                name: { type: "string", description: "Name TODO" },
                phone: { type: "string", description: "Phone TODO" },
                avatar: {
                  description: "Avatar TODO",
                  ref: "V2AttachmentDefault",
                  type: "object",
                  properties: {
                    uid: { type: "number", description: "Uid TODO" },
                    signedId: { type: "string", description: "Signed TODO" },
                    name: { type: "string", description: "Name TODO" },
                    url: { type: "string", description: "Url TODO" },
                    originalUrl: {
                      type: "string",
                      description: "Original url TODO"
                    },
                    contentType: {
                      type: "string",
                      description: "Content type TODO"
                    }
                  }
                }
              }
            },
            membershipRecordId: {
              type: "number",
              description: "Membership record TODO"
            },
            amount: { type: "string", description: "支付金额" },
            originalAmount: { type: "string", description: "原价" },
            discount: { type: "string", description: "Discount TODO" },
            products: { type: "string", description: "Products TODO" },
            actor: { type: "string", description: "Actor TODO" },
            note: { type: "string", description: "备注" }
          }
        },
        ref: "PartnerConsumptionRecordDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandPartnerConsumptionRecordConsumeRequest: {
    type: "object",
    properties: {
      partnerId: { type: "number", description: "商户", required: true },
      memberId: { type: "number", description: "用户", required: true },
      accountId: { type: "number", description: "账户", required: true },
      originalAmount: { type: "string", description: "原价", required: true },
      amount: { type: "string", description: "支付金额", required: true },
      note: { type: "string", description: "备注" },
      partnerProductIds: { type: "array", description: "商品" }
    }
  },
  V2BrandPartnerConsumptionRecordUpdateRequest: {
    type: "object",
    properties: {
      partnerProductId: { type: "number", description: "Partner product TODO" },
      note: { type: "string", description: "备注" }
    }
  },
  PartnerProductOption: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "商品名称" }
    }
  },
  PartnerProductDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "商品名称" },
      partnerId: { type: "number", description: "商户" },
      partnerName: { type: "string", description: "商户" },
      active: { type: "boolean", description: "激活" }
    }
  },
  PartnerProductOptionPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "商品名称" }
          }
        },
        ref: "PartnerProductOption"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  PartnerProductDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "商品名称" },
            partnerId: { type: "number", description: "商户" },
            partnerName: { type: "string", description: "商户" },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "PartnerProductDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandPartnerProductCreateRequest: {
    type: "object",
    properties: {
      partnerId: { type: "number", description: "商户", required: true },
      name: { type: "string", description: "商品名称", required: true },
      active: { type: "boolean", description: "激活", required: true }
    }
  },
  V2BrandPartnerProductUpdateRequest: {
    type: "object",
    properties: {
      name: { type: "string", description: "商品名称" },
      partnerId: { type: "number", description: "商户" },
      active: { type: "boolean", description: "激活" }
    }
  },
  PartnerDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "商户名称" },
            brandId: { type: "number", description: "Brand TODO" },
            discount: { type: "string", description: "储值折扣" },
            discountNote: { type: "string", description: "储值折扣说明" },
            logo: {
              description: "Logo",
              ref: "V2AttachmentDefault",
              type: "object",
              properties: {
                uid: { type: "number", description: "Uid TODO" },
                signedId: { type: "string", description: "Signed TODO" },
                name: { type: "string", description: "Name TODO" },
                url: { type: "string", description: "Url TODO" },
                originalUrl: {
                  type: "string",
                  description: "Original url TODO"
                },
                contentType: {
                  type: "string",
                  description: "Content type TODO"
                }
              }
            },
            productImage: {
              description: "产品介绍图片",
              ref: "V2AttachmentDefault",
              type: "object",
              properties: {
                uid: { type: "number", description: "Uid TODO" },
                signedId: { type: "string", description: "Signed TODO" },
                name: { type: "string", description: "Name TODO" },
                url: { type: "string", description: "Url TODO" },
                originalUrl: {
                  type: "string",
                  description: "Original url TODO"
                },
                contentType: {
                  type: "string",
                  description: "Content type TODO"
                }
              }
            },
            active: { type: "boolean", description: "激活" }
          }
        },
        ref: "PartnerDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandPartnerCreateRequest: {
    type: "object",
    properties: {
      name: { type: "string", description: "商户名称", required: true },
      discount: { type: "string", description: "储值折扣", required: true },
      discountNote: {
        type: "string",
        description: "储值折扣说明",
        required: true
      },
      active: { type: "boolean", description: "激活", required: true },
      logo: { type: "object", description: "Logo" },
      productImage: { type: "object", description: "产品介绍图片" }
    }
  },
  V2BrandPartnerUpdateRequest: {
    type: "object",
    properties: {
      name: { type: "string", description: "商户名称" },
      discount: { type: "string", description: "储值折扣" },
      discountNote: { type: "string", description: "储值折扣说明" },
      active: { type: "boolean", description: "激活" },
      logo: { type: "object", description: "Logo" },
      productImage: { type: "object", description: "产品介绍图片" }
    }
  },
  V2BrandCourseBookingOption: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      member: {
        description: "Member TODO",
        ref: "V2MemberMemberOption",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          phone: { type: "string", description: "Phone TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      workflowState: { type: "string", description: "Workflow state TODO" }
    }
  },
  V2BrandFinanceCoachCourseDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      courseName: { type: "string", description: "Course name TODO" },
      enCourseName: { type: "string", description: "En course name TODO" },
      productType: { type: "string", description: "Product type TODO" },
      startTime: {
        description: "Start time TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      zone: {
        description: "Zone TODO",
        ref: "V2StudioZoneOption",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          fullName: { type: "string", description: "Full name TODO" },
          eliteTraining: {
            type: "boolean",
            description: "Elite training TODO"
          },
          studio: {
            description: "Studio TODO",
            ref: "V2StudioStudioOption",
            type: "object",
            properties: {
              id: { type: "number", description: "Id TODO" },
              name: { type: "string", description: "Name TODO" },
              fullName: { type: "string", description: "Full name TODO" },
              shortName: { type: "string", description: "Short name TODO" }
            }
          }
        }
      },
      bookings: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            member: {
              description: "Member TODO",
              ref: "V2MemberMemberOption",
              type: "object",
              properties: {
                id: { type: "number", description: "Id TODO" },
                name: { type: "string", description: "Name TODO" },
                phone: { type: "string", description: "Phone TODO" },
                avatar: {
                  description: "Avatar TODO",
                  ref: "V2AttachmentDefault",
                  type: "object",
                  properties: {
                    uid: { type: "number", description: "Uid TODO" },
                    signedId: { type: "string", description: "Signed TODO" },
                    name: { type: "string", description: "Name TODO" },
                    url: { type: "string", description: "Url TODO" },
                    originalUrl: {
                      type: "string",
                      description: "Original url TODO"
                    },
                    contentType: {
                      type: "string",
                      description: "Content type TODO"
                    }
                  }
                }
              }
            },
            workflowState: {
              type: "string",
              description: "Workflow state TODO"
            }
          }
        },
        description: "Bookings TODO",
        ref: "V2BrandCourseBookingOption"
      }
    }
  },
  V2BrandFinanceMembershipRecordDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      value: { type: "string", description: "充值金额" },
      bonus: { type: "string", description: "赠送金额" },
      total: { type: "string", description: "Total TODO" },
      createdAt: {
        description: "创建时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      notes: { type: "string", description: "备注" },
      settlementAmount: { type: "string", description: "结算金额" },
      member: {
        description: "会员",
        ref: "V2MemberMemberOption",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          phone: { type: "string", description: "Phone TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      account: {
        description: "Account TODO",
        ref: "V2BrandMembershipAccountDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          member: {
            description: "会员",
            ref: "V2MemberMemberOption",
            type: "object",
            properties: {
              id: { type: "number", description: "Id TODO" },
              name: { type: "string", description: "Name TODO" },
              phone: { type: "string", description: "Phone TODO" },
              avatar: {
                description: "Avatar TODO",
                ref: "V2AttachmentDefault",
                type: "object",
                properties: {
                  uid: { type: "number", description: "Uid TODO" },
                  signedId: { type: "string", description: "Signed TODO" },
                  name: { type: "string", description: "Name TODO" },
                  url: { type: "string", description: "Url TODO" },
                  originalUrl: {
                    type: "string",
                    description: "Original url TODO"
                  },
                  contentType: {
                    type: "string",
                    description: "Content type TODO"
                  }
                }
              }
            }
          },
          value: { type: "string", description: "真实余额" },
          bonus: { type: "string", description: "赠送余额" },
          balance: { type: "string", description: "余额" },
          settlementPrice: { type: "string", description: "结算单价" },
          settlementAmount: { type: "string", description: "结算金额" }
        }
      }
    }
  },
  V2BrandFinanceMembershipRecordSummary: {
    type: "object",
    properties: {
      totalCount: { type: "number", description: "Total count TODO" },
      totalAmount: { type: "string", description: "Total amount TODO" }
    }
  },
  V2BrandFinanceMembershipRecordDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            value: { type: "string", description: "充值金额" },
            bonus: { type: "string", description: "赠送金额" },
            total: { type: "string", description: "Total TODO" },
            createdAt: {
              description: "创建时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            notes: { type: "string", description: "备注" },
            settlementAmount: { type: "string", description: "结算金额" },
            member: {
              description: "会员",
              ref: "V2MemberMemberOption",
              type: "object",
              properties: {
                id: { type: "number", description: "Id TODO" },
                name: { type: "string", description: "Name TODO" },
                phone: { type: "string", description: "Phone TODO" },
                avatar: {
                  description: "Avatar TODO",
                  ref: "V2AttachmentDefault",
                  type: "object",
                  properties: {
                    uid: { type: "number", description: "Uid TODO" },
                    signedId: { type: "string", description: "Signed TODO" },
                    name: { type: "string", description: "Name TODO" },
                    url: { type: "string", description: "Url TODO" },
                    originalUrl: {
                      type: "string",
                      description: "Original url TODO"
                    },
                    contentType: {
                      type: "string",
                      description: "Content type TODO"
                    }
                  }
                }
              }
            },
            account: {
              description: "Account TODO",
              ref: "V2BrandMembershipAccountDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                member: {
                  description: "会员",
                  ref: "V2MemberMemberOption",
                  type: "object",
                  properties: {
                    id: { type: "number", description: "Id TODO" },
                    name: { type: "string", description: "Name TODO" },
                    phone: { type: "string", description: "Phone TODO" },
                    avatar: {
                      description: "Avatar TODO",
                      ref: "V2AttachmentDefault",
                      type: "object",
                      properties: {
                        uid: { type: "number", description: "Uid TODO" },
                        signedId: {
                          type: "string",
                          description: "Signed TODO"
                        },
                        name: { type: "string", description: "Name TODO" },
                        url: { type: "string", description: "Url TODO" },
                        originalUrl: {
                          type: "string",
                          description: "Original url TODO"
                        },
                        contentType: {
                          type: "string",
                          description: "Content type TODO"
                        }
                      }
                    }
                  }
                },
                value: { type: "string", description: "真实余额" },
                bonus: { type: "string", description: "赠送余额" },
                balance: { type: "string", description: "余额" },
                settlementPrice: { type: "string", description: "结算单价" },
                settlementAmount: { type: "string", description: "结算金额" }
              }
            }
          }
        },
        ref: "V2BrandFinanceMembershipRecordDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandCouponMemberCouponOption: {
    type: "object",
    properties: { id: { type: "number", description: "ID" } }
  },
  V2BrandCouponCouponOption: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "代金券名称" }
    }
  },
  V2BrandCouponCouponDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "代金券名称" },
      value: { type: "string", description: "代金券面值" },
      validityPeriod: { type: "number", description: "有效天数" },
      active: { type: "boolean", description: "激活" },
      usageThreshold: { type: "string", description: "Usage threshold TODO" },
      applyToAllStudio: {
        type: "boolean",
        description: "Apply to all studio TODO"
      },
      applyToAllProduct: {
        type: "boolean",
        description: "Apply to all product TODO"
      },
      fullDiscount: { type: "boolean", description: "全额抵扣" },
      availableTimeStart: {
        type: "object",
        description: "Available time start TODO"
      },
      availableTimeEnd: {
        type: "object",
        description: "Available time end TODO"
      }
    }
  },
  V2BrandCouponMemberCouponDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      member: {
        description: "Member TODO",
        ref: "V2MemberMemberOption",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          phone: { type: "string", description: "Phone TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      coupon: {
        description: "代金券",
        ref: "V2BrandCouponCouponDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "ID" },
          name: { type: "string", description: "代金券名称" },
          value: { type: "string", description: "代金券面值" },
          validityPeriod: { type: "number", description: "有效天数" },
          active: { type: "boolean", description: "激活" },
          usageThreshold: {
            type: "string",
            description: "Usage threshold TODO"
          },
          applyToAllStudio: {
            type: "boolean",
            description: "Apply to all studio TODO"
          },
          applyToAllProduct: {
            type: "boolean",
            description: "Apply to all product TODO"
          },
          fullDiscount: { type: "boolean", description: "全额抵扣" },
          availableTimeStart: {
            type: "object",
            description: "Available time start TODO"
          },
          availableTimeEnd: {
            type: "object",
            description: "Available time end TODO"
          }
        }
      },
      sourceId: { type: "number", description: "Source TODO" },
      sourceType: { type: "string", description: "Source type TODO" },
      sourceDescription: {
        type: "string",
        description: "Source description TODO"
      },
      expiryDate: {
        description: "Expiry date TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      createdAt: {
        description: "Created at TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      settlementPrice: { type: "string", description: "Settlement price TODO" }
    }
  },
  V2BrandCouponMemberCouponSummary: {
    type: "object",
    properties: {
      totalCount: { type: "number", description: "Total count TODO" },
      totalAmount: { type: "string", description: "Total amount TODO" }
    }
  },
  V2BrandCouponMemberCouponDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            member: {
              description: "Member TODO",
              ref: "V2MemberMemberOption",
              type: "object",
              properties: {
                id: { type: "number", description: "Id TODO" },
                name: { type: "string", description: "Name TODO" },
                phone: { type: "string", description: "Phone TODO" },
                avatar: {
                  description: "Avatar TODO",
                  ref: "V2AttachmentDefault",
                  type: "object",
                  properties: {
                    uid: { type: "number", description: "Uid TODO" },
                    signedId: { type: "string", description: "Signed TODO" },
                    name: { type: "string", description: "Name TODO" },
                    url: { type: "string", description: "Url TODO" },
                    originalUrl: {
                      type: "string",
                      description: "Original url TODO"
                    },
                    contentType: {
                      type: "string",
                      description: "Content type TODO"
                    }
                  }
                }
              }
            },
            coupon: {
              description: "代金券",
              ref: "V2BrandCouponCouponDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "ID" },
                name: { type: "string", description: "代金券名称" },
                value: { type: "string", description: "代金券面值" },
                validityPeriod: { type: "number", description: "有效天数" },
                active: { type: "boolean", description: "激活" },
                usageThreshold: {
                  type: "string",
                  description: "Usage threshold TODO"
                },
                applyToAllStudio: {
                  type: "boolean",
                  description: "Apply to all studio TODO"
                },
                applyToAllProduct: {
                  type: "boolean",
                  description: "Apply to all product TODO"
                },
                fullDiscount: { type: "boolean", description: "全额抵扣" },
                availableTimeStart: {
                  type: "object",
                  description: "Available time start TODO"
                },
                availableTimeEnd: {
                  type: "object",
                  description: "Available time end TODO"
                }
              }
            },
            sourceId: { type: "number", description: "Source TODO" },
            sourceType: { type: "string", description: "Source type TODO" },
            sourceDescription: {
              type: "string",
              description: "Source description TODO"
            },
            expiryDate: {
              description: "Expiry date TODO",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            createdAt: {
              description: "Created at TODO",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            settlementPrice: {
              type: "string",
              description: "Settlement price TODO"
            }
          }
        },
        ref: "V2BrandCouponMemberCouponDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandReportFinanceCancelExpirationRequest: {
    type: "object",
    properties: {
      validityPeriod: { type: "number", description: "有效期", required: true },
      notes: { type: "string", description: "备注", required: true }
    }
  },
  V2StudioCoachDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      coachId: { type: "number", description: "Coach TODO" },
      studioId: { type: "number", description: "Studio TODO" },
      name: { type: "string", description: "Name TODO" },
      displayName: { type: "string", description: "Display name TODO" },
      phone: { type: "string", description: "Phone TODO" },
      avatar: {
        description: "Avatar TODO",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      },
      position: { type: "number", description: "Position TODO" },
      active: { type: "boolean", description: "Active TODO" }
    }
  },
  V2StudioCoachOptionPagedArray: {
    type: "object",
    properties: {
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2StudioCoachDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            coachId: { type: "number", description: "Coach TODO" },
            studioId: { type: "number", description: "Studio TODO" },
            name: { type: "string", description: "Name TODO" },
            displayName: { type: "string", description: "Display name TODO" },
            phone: { type: "string", description: "Phone TODO" },
            avatar: {
              description: "Avatar TODO",
              ref: "V2AttachmentDefault",
              type: "object",
              properties: {
                uid: { type: "number", description: "Uid TODO" },
                signedId: { type: "string", description: "Signed TODO" },
                name: { type: "string", description: "Name TODO" },
                url: { type: "string", description: "Url TODO" },
                originalUrl: {
                  type: "string",
                  description: "Original url TODO"
                },
                contentType: {
                  type: "string",
                  description: "Content type TODO"
                }
              }
            },
            position: { type: "number", description: "Position TODO" },
            active: { type: "boolean", description: "Active TODO" }
          }
        },
        ref: "V2StudioCoachDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandStudioCoachCreateRequest: {
    type: "object",
    properties: {
      coachId: { type: "number", description: "教练", required: true },
      active: { type: "boolean", description: "激活" }
    }
  },
  V2BrandStudioCoachUpdateRequest: {
    type: "object",
    properties: {
      position: { type: "number", description: "序号", required: true }
    }
  },
  V2StudioProductOption: {
    type: "object",
    properties: { id: { type: "number", description: "Id TODO" } }
  },
  V2StudioProductDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      price: { type: "string", description: "Price TODO" },
      position: { type: "number", description: "序号" }
    }
  },
  V2StudioProductOptionPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: { id: { type: "number", description: "Id TODO" } }
        },
        ref: "V2StudioProductOption"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2StudioProductDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            name: { type: "string", description: "Name TODO" },
            price: { type: "string", description: "Price TODO" },
            position: { type: "number", description: "序号" }
          }
        },
        ref: "V2StudioProductDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2BrandStudioProductCreateRequest: {
    type: "object",
    properties: {
      productId: { type: "number", description: "训练产品", required: true }
    }
  },
  V2BrandStudioProductUpdateRequest: {
    type: "object",
    properties: {
      position: { type: "number", description: "序号", required: true }
    }
  },
  V2StudioAccessLogOption: {
    type: "object",
    properties: { id: { type: "number", description: "Id TODO" } }
  },
  V2StudioAccessLogDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      member: {
        description: "Member TODO",
        ref: "V2MemberMemberOption",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          phone: { type: "string", description: "Phone TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      studio: {
        description: "Studio TODO",
        ref: "V2StudioStudioOption",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          fullName: { type: "string", description: "Full name TODO" },
          shortName: { type: "string", description: "Short name TODO" }
        }
      },
      createdAt: {
        description: "Created at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  V2StudioAccessLogDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            member: {
              description: "Member TODO",
              ref: "V2MemberMemberOption",
              type: "object",
              properties: {
                id: { type: "number", description: "Id TODO" },
                name: { type: "string", description: "Name TODO" },
                phone: { type: "string", description: "Phone TODO" },
                avatar: {
                  description: "Avatar TODO",
                  ref: "V2AttachmentDefault",
                  type: "object",
                  properties: {
                    uid: { type: "number", description: "Uid TODO" },
                    signedId: { type: "string", description: "Signed TODO" },
                    name: { type: "string", description: "Name TODO" },
                    url: { type: "string", description: "Url TODO" },
                    originalUrl: {
                      type: "string",
                      description: "Original url TODO"
                    },
                    contentType: {
                      type: "string",
                      description: "Content type TODO"
                    }
                  }
                }
              }
            },
            studio: {
              description: "Studio TODO",
              ref: "V2StudioStudioOption",
              type: "object",
              properties: {
                id: { type: "number", description: "Id TODO" },
                name: { type: "string", description: "Name TODO" },
                fullName: { type: "string", description: "Full name TODO" },
                shortName: { type: "string", description: "Short name TODO" }
              }
            },
            createdAt: {
              description: "Created at TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        ref: "V2StudioAccessLogDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2CoachCourseCourseOption: {
    type: "object",
    properties: { id: { type: "number", description: "Id TODO" } }
  },
  V2CoachStudioZoneOption: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      fullName: { type: "string", description: "Full name TODO" },
      eliteTraining: { type: "boolean", description: "可上私教" }
    }
  },
  V2CoachCourseBookingOption: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      avatar: {
        description: "Avatar TODO",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      },
      name: { type: "string", description: "Name TODO" },
      workflowState: { type: "string", description: "Workflow state TODO" }
    }
  },
  V2CoachCourseBookingMemberBooking: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      stateCn: { type: "string", description: "State cn TODO" },
      course: {
        description: "Course TODO",
        ref: "V2CoachCourseCourseDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          courseName: { type: "string", description: "Course name TODO" },
          enCourseName: { type: "string", description: "En course name TODO" },
          productType: { type: "string", description: "Product type TODO" },
          primaryCoach: {
            description: "Primary coach TODO",
            ref: "V2CoachCoachDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "Id TODO" },
              name: { type: "string", description: "Name TODO" },
              displayName: { type: "string", description: "Display name TODO" },
              avatar: {
                description: "Avatar TODO",
                ref: "V2AttachmentDefault",
                type: "object",
                properties: {
                  uid: { type: "number", description: "Uid TODO" },
                  signedId: { type: "string", description: "Signed TODO" },
                  name: { type: "string", description: "Name TODO" },
                  url: { type: "string", description: "Url TODO" },
                  originalUrl: {
                    type: "string",
                    description: "Original url TODO"
                  },
                  contentType: {
                    type: "string",
                    description: "Content type TODO"
                  }
                }
              }
            }
          },
          startTime: {
            description: "Start time TODO",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          zone: {
            description: "Zone TODO",
            ref: "V2CoachStudioZoneOption",
            type: "object",
            properties: {
              id: { type: "number", description: "Id TODO" },
              name: { type: "string", description: "Name TODO" },
              fullName: { type: "string", description: "Full name TODO" },
              eliteTraining: { type: "boolean", description: "可上私教" }
            }
          },
          bookings: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "Id TODO" },
                avatar: {
                  description: "Avatar TODO",
                  ref: "V2AttachmentDefault",
                  type: "object",
                  properties: {
                    uid: { type: "number", description: "Uid TODO" },
                    signedId: { type: "string", description: "Signed TODO" },
                    name: { type: "string", description: "Name TODO" },
                    url: { type: "string", description: "Url TODO" },
                    originalUrl: {
                      type: "string",
                      description: "Original url TODO"
                    },
                    contentType: {
                      type: "string",
                      description: "Content type TODO"
                    }
                  }
                },
                name: { type: "string", description: "Name TODO" },
                workflowState: {
                  type: "string",
                  description: "Workflow state TODO"
                }
              }
            },
            description: "Bookings TODO",
            ref: "V2CoachCourseBookingOption"
          }
        }
      }
    }
  },
  V2CoachCourseCourseDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      courseName: { type: "string", description: "Course name TODO" },
      enCourseName: { type: "string", description: "En course name TODO" },
      productType: { type: "string", description: "Product type TODO" },
      primaryCoach: {
        description: "Primary coach TODO",
        ref: "V2CoachCoachDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          displayName: { type: "string", description: "Display name TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      startTime: {
        description: "Start time TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      zone: {
        description: "Zone TODO",
        ref: "V2CoachStudioZoneOption",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          fullName: { type: "string", description: "Full name TODO" },
          eliteTraining: { type: "boolean", description: "可上私教" }
        }
      },
      bookings: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            avatar: {
              description: "Avatar TODO",
              ref: "V2AttachmentDefault",
              type: "object",
              properties: {
                uid: { type: "number", description: "Uid TODO" },
                signedId: { type: "string", description: "Signed TODO" },
                name: { type: "string", description: "Name TODO" },
                url: { type: "string", description: "Url TODO" },
                originalUrl: {
                  type: "string",
                  description: "Original url TODO"
                },
                contentType: {
                  type: "string",
                  description: "Content type TODO"
                }
              }
            },
            name: { type: "string", description: "Name TODO" },
            workflowState: {
              type: "string",
              description: "Workflow state TODO"
            }
          }
        },
        description: "Bookings TODO",
        ref: "V2CoachCourseBookingOption"
      }
    }
  },
  V2CoachCourseCourseStatistic: {
    type: "object",
    properties: {
      groupTraining: { type: "number", description: "Group training TODO" },
      eliteTraining: { type: "number", description: "可上私教" },
      camp: { type: "number", description: "Camp TODO" },
      assistant: { type: "number", description: "Assistant TODO" },
      days: { type: "number", description: "Days TODO" },
      total: { type: "number", description: "Total TODO" }
    }
  },
  V2CoachCourseCourseDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            courseName: { type: "string", description: "Course name TODO" },
            enCourseName: {
              type: "string",
              description: "En course name TODO"
            },
            productType: { type: "string", description: "Product type TODO" },
            primaryCoach: {
              description: "Primary coach TODO",
              ref: "V2CoachCoachDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "Id TODO" },
                name: { type: "string", description: "Name TODO" },
                displayName: {
                  type: "string",
                  description: "Display name TODO"
                },
                avatar: {
                  description: "Avatar TODO",
                  ref: "V2AttachmentDefault",
                  type: "object",
                  properties: {
                    uid: { type: "number", description: "Uid TODO" },
                    signedId: { type: "string", description: "Signed TODO" },
                    name: { type: "string", description: "Name TODO" },
                    url: { type: "string", description: "Url TODO" },
                    originalUrl: {
                      type: "string",
                      description: "Original url TODO"
                    },
                    contentType: {
                      type: "string",
                      description: "Content type TODO"
                    }
                  }
                }
              }
            },
            startTime: {
              description: "Start time TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            zone: {
              description: "Zone TODO",
              ref: "V2CoachStudioZoneOption",
              type: "object",
              properties: {
                id: { type: "number", description: "Id TODO" },
                name: { type: "string", description: "Name TODO" },
                fullName: { type: "string", description: "Full name TODO" },
                eliteTraining: { type: "boolean", description: "可上私教" }
              }
            },
            bookings: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "Id TODO" },
                  avatar: {
                    description: "Avatar TODO",
                    ref: "V2AttachmentDefault",
                    type: "object",
                    properties: {
                      uid: { type: "number", description: "Uid TODO" },
                      signedId: { type: "string", description: "Signed TODO" },
                      name: { type: "string", description: "Name TODO" },
                      url: { type: "string", description: "Url TODO" },
                      originalUrl: {
                        type: "string",
                        description: "Original url TODO"
                      },
                      contentType: {
                        type: "string",
                        description: "Content type TODO"
                      }
                    }
                  },
                  name: { type: "string", description: "Name TODO" },
                  workflowState: {
                    type: "string",
                    description: "Workflow state TODO"
                  }
                }
              },
              description: "Bookings TODO",
              ref: "V2CoachCourseBookingOption"
            }
          }
        },
        ref: "V2CoachCourseCourseDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2CoachCourseCourseBookRequest: {
    type: "object",
    properties: {
      memberId: { type: "number", required: true },
      accountId: { type: "number", required: true },
      memberCouponId: { type: "number" }
    }
  },
  V2CoachEliteBookingCreateRequest: {
    type: "object",
    properties: {
      memberId: { type: "number", required: true },
      accountId: { type: "number", required: true },
      productId: { type: "number", required: true },
      coachId: { type: "number", required: true },
      zoneId: { type: "number", required: true },
      ignoreTimeConflict: { type: "boolean" },
      startTimes: { type: "array", required: true },
      memberCouponId: { type: "number" }
    }
  },
  V2CoachEliteBookingCheckCoachAvailabilityRequest: {
    type: "object",
    properties: {
      coachId: { type: "number", required: true },
      productId: { type: "number", required: true },
      startTimes: { type: "array", required: true }
    }
  },
  V2CoachEliteMemberBase: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      realName: { type: "string", description: "Real name TODO" },
      phone: { type: "string", description: "Phone TODO" },
      gender: { type: "string", description: "Gender TODO" },
      avatar: {
        description: "Avatar TODO",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      },
      followed: { type: "boolean", description: "Followed TODO" }
    }
  },
  V2CoachEliteMemberActive: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      realName: { type: "string", description: "Real name TODO" },
      phone: { type: "string", description: "Phone TODO" },
      gender: { type: "string", description: "Gender TODO" },
      avatar: { description: "Avatar TODO" },
      followed: { type: "boolean", description: "Followed TODO" },
      courseCount: { type: "number", description: "Course count TODO" }
    }
  },
  V2CoachEliteMemberInactive: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      realName: { type: "string", description: "Real name TODO" },
      phone: { type: "string", description: "Phone TODO" },
      gender: { type: "string", description: "Gender TODO" },
      avatar: { description: "Avatar TODO" },
      followed: { type: "boolean", description: "Followed TODO" },
      lastCourseTime: {
        description: "Last course time TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  V2CoachEliteMemberChruned: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      realName: { type: "string", description: "Real name TODO" },
      phone: { type: "string", description: "Phone TODO" },
      gender: { type: "string", description: "Gender TODO" },
      avatar: { description: "Avatar TODO" },
      followed: { type: "boolean", description: "Followed TODO" },
      totalBalance: { type: "number", description: "Total balance TODO" }
    }
  },
  V2CoachEliteMemberServed: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      realName: { type: "string", description: "Real name TODO" },
      phone: { type: "string", description: "Phone TODO" },
      gender: { type: "string", description: "Gender TODO" },
      avatar: { description: "Avatar TODO" },
      followed: { type: "boolean", description: "Followed TODO" },
      lastCourseTime: {
        description: "Last course time TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  V2CoachEliteMemberFollowed: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      realName: { type: "string", description: "Real name TODO" },
      phone: { type: "string", description: "Phone TODO" },
      gender: { type: "string", description: "Gender TODO" },
      avatar: { description: "Avatar TODO" },
      followed: { type: "boolean", description: "Followed TODO" },
      lastCourseTime: {
        description: "Last course time TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  V2CoachEliteMemberActivePagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            name: { type: "string", description: "Name TODO" },
            realName: { type: "string", description: "Real name TODO" },
            phone: { type: "string", description: "Phone TODO" },
            gender: { type: "string", description: "Gender TODO" },
            avatar: { description: "Avatar TODO" },
            followed: { type: "boolean", description: "Followed TODO" },
            courseCount: { type: "number", description: "Course count TODO" }
          }
        },
        ref: "V2CoachEliteMemberActive"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2CoachEliteMemberInactivePagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            name: { type: "string", description: "Name TODO" },
            realName: { type: "string", description: "Real name TODO" },
            phone: { type: "string", description: "Phone TODO" },
            gender: { type: "string", description: "Gender TODO" },
            avatar: { description: "Avatar TODO" },
            followed: { type: "boolean", description: "Followed TODO" },
            lastCourseTime: {
              description: "Last course time TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        ref: "V2CoachEliteMemberInactive"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2CoachEliteMemberChrunedPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            name: { type: "string", description: "Name TODO" },
            realName: { type: "string", description: "Real name TODO" },
            phone: { type: "string", description: "Phone TODO" },
            gender: { type: "string", description: "Gender TODO" },
            avatar: { description: "Avatar TODO" },
            followed: { type: "boolean", description: "Followed TODO" },
            totalBalance: { type: "number", description: "Total balance TODO" }
          }
        },
        ref: "V2CoachEliteMemberChruned"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2CoachEliteMemberServedPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            name: { type: "string", description: "Name TODO" },
            realName: { type: "string", description: "Real name TODO" },
            phone: { type: "string", description: "Phone TODO" },
            gender: { type: "string", description: "Gender TODO" },
            avatar: { description: "Avatar TODO" },
            followed: { type: "boolean", description: "Followed TODO" },
            lastCourseTime: {
              description: "Last course time TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        ref: "V2CoachEliteMemberServed"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2CoachEliteMemberFollowedPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            name: { type: "string", description: "Name TODO" },
            realName: { type: "string", description: "Real name TODO" },
            phone: { type: "string", description: "Phone TODO" },
            gender: { type: "string", description: "Gender TODO" },
            avatar: { description: "Avatar TODO" },
            followed: { type: "boolean", description: "Followed TODO" },
            lastCourseTime: {
              description: "Last course time TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        ref: "V2CoachEliteMemberFollowed"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2CoachFitnessTestRecordCreateRequest: {
    type: "object",
    properties: {
      memberId: { type: "number", description: "Member TODO", required: true },
      date: {
        type: "string",
        format: "date",
        description: "Date TODO",
        required: true
      },
      data: { type: "array", description: "Data TODO", required: true },
      dateOfBirth: {
        type: "string",
        format: "date",
        description: "Date of birth TODO"
      },
      height: { type: "string", description: "Height TODO" },
      gender: { type: "string", description: "Gender TODO" }
    }
  },
  V2CoachLeaveDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      startTime: {
        description: "Start time TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      endTime: {
        description: "End time TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  V2CoachLeaveCreateRequest: {
    type: "object",
    properties: {
      startTime: {
        type: "string",
        format: "date-time",
        description: "Start time TODO",
        required: true
      },
      endTime: {
        type: "string",
        format: "date-time",
        description: "End time TODO",
        required: true
      }
    }
  },
  V2CoachMemberDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      realName: { type: "string", description: "Real name TODO" },
      phone: { type: "string", description: "Phone TODO" },
      gender: { type: "string", description: "Gender TODO" },
      height: { type: "number", description: "Height TODO" },
      weight: { type: "number", description: "Weight TODO" },
      dateOfBirth: {
        description: "Date of birth TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      age: { type: "number", description: "Age TODO" },
      coach: {
        description: "Coach TODO",
        ref: "V2CoachCoachDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          displayName: { type: "string", description: "Display name TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      avatar: {
        description: "Avatar TODO",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      },
      tagList: {
        type: "array",
        items: { type: "string" },
        description: "Tag list TODO"
      },
      followed: { type: "boolean", description: "Followed TODO" }
    }
  },
  V2CoachMembershipCardDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      productType: { type: "string", description: "Product type TODO" }
    }
  },
  V2CoachMembershipAccountDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      accountType: { type: "string", description: "Account type TODO" },
      balance: { type: "string", description: "Balance TODO" },
      value: { type: "string", description: "Value TODO" },
      bonus: { type: "string", description: "Bonus TODO" },
      shared: { type: "boolean", description: "Shared TODO" },
      workflowState: { type: "string", description: "Workflow state TODO" },
      expiryDate: {
        description: "Expiry date TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      coaches: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            name: { type: "string", description: "Name TODO" },
            displayName: { type: "string", description: "Display name TODO" },
            avatar: {
              description: "Avatar TODO",
              ref: "V2AttachmentDefault",
              type: "object",
              properties: {
                uid: { type: "number", description: "Uid TODO" },
                signedId: { type: "string", description: "Signed TODO" },
                name: { type: "string", description: "Name TODO" },
                url: { type: "string", description: "Url TODO" },
                originalUrl: {
                  type: "string",
                  description: "Original url TODO"
                },
                contentType: {
                  type: "string",
                  description: "Content type TODO"
                }
              }
            }
          }
        },
        description: "Coaches TODO",
        ref: "V2CoachCoachDefault"
      },
      card: {
        description: "Card TODO",
        ref: "V2CoachMembershipCardDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          productType: { type: "string", description: "Product type TODO" }
        }
      }
    }
  },
  V2CoachMemberCouponDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      value: { type: "string", description: "Value TODO" },
      fullDiscount: { type: "boolean", description: "Full discount TODO" },
      expiryDate: {
        description: "Expiry date TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      workflowState: { type: "string", description: "Workflow state TODO" },
      notes: { type: "string", description: "Notes TODO" }
    }
  },
  V2CoachMemberAccount: {
    type: "object",
    properties: {
      accounts: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            accountType: { type: "string", description: "Account type TODO" },
            balance: { type: "string", description: "Balance TODO" },
            value: { type: "string", description: "Value TODO" },
            bonus: { type: "string", description: "Bonus TODO" },
            shared: { type: "boolean", description: "Shared TODO" },
            workflowState: {
              type: "string",
              description: "Workflow state TODO"
            },
            expiryDate: {
              description: "Expiry date TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            coaches: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "Id TODO" },
                  name: { type: "string", description: "Name TODO" },
                  displayName: {
                    type: "string",
                    description: "Display name TODO"
                  },
                  avatar: {
                    description: "Avatar TODO",
                    ref: "V2AttachmentDefault",
                    type: "object",
                    properties: {
                      uid: { type: "number", description: "Uid TODO" },
                      signedId: { type: "string", description: "Signed TODO" },
                      name: { type: "string", description: "Name TODO" },
                      url: { type: "string", description: "Url TODO" },
                      originalUrl: {
                        type: "string",
                        description: "Original url TODO"
                      },
                      contentType: {
                        type: "string",
                        description: "Content type TODO"
                      }
                    }
                  }
                }
              },
              description: "Coaches TODO",
              ref: "V2CoachCoachDefault"
            },
            card: {
              description: "Card TODO",
              ref: "V2CoachMembershipCardDefault",
              type: "object",
              properties: {
                id: { type: "number", description: "Id TODO" },
                name: { type: "string", description: "Name TODO" },
                productType: {
                  type: "string",
                  description: "Product type TODO"
                }
              }
            }
          }
        },
        description: "Accounts TODO",
        ref: "V2CoachMembershipAccountDefault"
      },
      coupons: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            name: { type: "string", description: "Name TODO" },
            value: { type: "string", description: "Value TODO" },
            fullDiscount: {
              type: "boolean",
              description: "Full discount TODO"
            },
            expiryDate: {
              description: "Expiry date TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            workflowState: {
              type: "string",
              description: "Workflow state TODO"
            },
            notes: { type: "string", description: "Notes TODO" }
          }
        },
        description: "Coupons TODO",
        ref: "V2CoachMemberCouponDefault"
      }
    }
  },
  V2CoachMemberUpdateRequest: {
    type: "object",
    properties: {
      tagList: { type: "array", description: "标签" },
      fitnessTestStartDate: {
        type: "string",
        format: "date",
        description: "Fitness test start date TODO"
      },
      dateOfBirth: { type: "string", format: "date", description: "生日" },
      gender: { type: "string", description: "性别" },
      height: { type: "string", description: "Height TODO" },
      weight: { type: "string", description: "体重" }
    }
  },
  CoachTasPackageOption: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" }
    }
  },
  CoachTasPackageDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      serial: { type: "string", description: "Serial TODO" },
      name: { type: "string", description: "Name TODO" },
      desc: { type: "object", description: "Desc TODO" },
      active: { type: "boolean", description: "Active TODO" },
      createdAt: {
        description: "Created at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  V2MobileMembershipCardList: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      productType: { type: "string", description: "Product type TODO" }
    }
  },
  V2MobileMembershipCardDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      productType: { type: "string", description: "Product type TODO" }
    }
  },
  V2MobileMembershipAccountDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      accountType: { type: "string", description: "Account type TODO" },
      balance: { type: "string", description: "Balance TODO" },
      value: { type: "string", description: "Value TODO" },
      bonus: { type: "string", description: "Bonus TODO" },
      shared: { type: "boolean", description: "Shared TODO" },
      workflowState: { type: "string", description: "Workflow state TODO" },
      expiryDate: {
        description: "Expiry date TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      coaches: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            name: { type: "string", description: "Name TODO" },
            displayName: { type: "string", description: "Display name TODO" },
            avatar: {
              description: "Avatar TODO",
              ref: "V2AttachmentDefault",
              type: "object",
              properties: {
                uid: { type: "number", description: "Uid TODO" },
                signedId: { type: "string", description: "Signed TODO" },
                name: { type: "string", description: "Name TODO" },
                url: { type: "string", description: "Url TODO" },
                originalUrl: {
                  type: "string",
                  description: "Original url TODO"
                },
                contentType: {
                  type: "string",
                  description: "Content type TODO"
                }
              }
            }
          }
        },
        description: "Coaches TODO",
        ref: "V2CoachCoachDefault"
      },
      card: {
        description: "Card TODO",
        ref: "V2MobileMembershipCardDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          productType: { type: "string", description: "Product type TODO" }
        }
      }
    }
  },
  V2MobileEliteTrainingCoachDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      avatar: {
        description: "Avatar TODO",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      },
      displayName: { type: "string", description: "Display name TODO" },
      state: { type: "string", description: "State TODO" },
      price: { type: "number", description: "Price TODO" }
    }
  },
  V2MobileEliteTrainingProductDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      price: { type: "number", description: "Price TODO" },
      duration: { type: "number", description: "Duration TODO" },
      tagListText: { type: "string", description: "Tag list text TODO" },
      banners: {
        type: "array",
        items: {
          type: "object",
          properties: {
            uid: { type: "number", description: "Uid TODO" },
            signedId: { type: "string", description: "Signed TODO" },
            name: { type: "string", description: "Name TODO" },
            url: { type: "string", description: "Url TODO" },
            originalUrl: { type: "string", description: "Original url TODO" },
            contentType: { type: "string", description: "Content type TODO" }
          }
        },
        description: "Banners TODO",
        ref: "V2AttachmentDefault"
      },
      entryPrice: { type: "string", description: "Entry price TODO" }
    }
  },
  V2MobileEliteTrainingStudioDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      fullName: { type: "string", description: "Full name TODO" },
      shortName: { type: "string", description: "Short name TODO" },
      address: { type: "string", description: "Address TODO" },
      longitude: { type: "string", description: "Longitude TODO" },
      latitude: { type: "string", description: "Latitude TODO" },
      distance: { type: "string", description: "Distance TODO" },
      primaryImage: {
        description: "Primary image TODO",
        ref: "V2AttachmentDefault",
        type: "object",
        properties: {
          uid: { type: "number", description: "Uid TODO" },
          signedId: { type: "string", description: "Signed TODO" },
          name: { type: "string", description: "Name TODO" },
          url: { type: "string", description: "Url TODO" },
          originalUrl: { type: "string", description: "Original url TODO" },
          contentType: { type: "string", description: "Content type TODO" }
        }
      },
      images: {
        type: "array",
        items: {
          type: "object",
          properties: {
            uid: { type: "number", description: "Uid TODO" },
            signedId: { type: "string", description: "Signed TODO" },
            name: { type: "string", description: "Name TODO" },
            url: { type: "string", description: "Url TODO" },
            originalUrl: { type: "string", description: "Original url TODO" },
            contentType: { type: "string", description: "Content type TODO" }
          }
        },
        description: "Images TODO",
        ref: "V2AttachmentDefault"
      },
      nearest: { type: "boolean", description: "Nearest TODO" },
      coaches: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            avatar: {
              description: "Avatar TODO",
              ref: "V2AttachmentDefault",
              type: "object",
              properties: {
                uid: { type: "number", description: "Uid TODO" },
                signedId: { type: "string", description: "Signed TODO" },
                name: { type: "string", description: "Name TODO" },
                url: { type: "string", description: "Url TODO" },
                originalUrl: {
                  type: "string",
                  description: "Original url TODO"
                },
                contentType: {
                  type: "string",
                  description: "Content type TODO"
                }
              }
            },
            displayName: { type: "string", description: "Display name TODO" },
            state: { type: "string", description: "State TODO" },
            price: { type: "number", description: "Price TODO" }
          }
        },
        description: "Coaches TODO",
        ref: "V2MobileEliteTrainingCoachDefault"
      },
      products: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            name: { type: "string", description: "Name TODO" },
            price: { type: "number", description: "Price TODO" },
            duration: { type: "number", description: "Duration TODO" },
            tagListText: { type: "string", description: "Tag list text TODO" },
            banners: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  uid: { type: "number", description: "Uid TODO" },
                  signedId: { type: "string", description: "Signed TODO" },
                  name: { type: "string", description: "Name TODO" },
                  url: { type: "string", description: "Url TODO" },
                  originalUrl: {
                    type: "string",
                    description: "Original url TODO"
                  },
                  contentType: {
                    type: "string",
                    description: "Content type TODO"
                  }
                }
              },
              description: "Banners TODO",
              ref: "V2AttachmentDefault"
            },
            entryPrice: { type: "string", description: "Entry price TODO" }
          }
        },
        description: "Products TODO",
        ref: "V2MobileEliteTrainingProductDefault"
      }
    }
  },
  V2MobileEliteTrainingZoneDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      eliteTraining: { type: "boolean", description: "可上私教" }
    }
  },
  V2MobileEliteTrainingStudioList: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      fullName: { type: "string", description: "Full name TODO" },
      shortName: { type: "string", description: "Short name TODO" },
      address: { type: "string", description: "Address TODO" },
      longitude: { type: "string", description: "Longitude TODO" },
      latitude: { type: "string", description: "Latitude TODO" },
      distance: { type: "string", description: "Distance TODO" },
      zones: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            name: { type: "string", description: "Name TODO" },
            eliteTraining: { type: "boolean", description: "可上私教" }
          }
        },
        description: "Zones TODO",
        ref: "V2MobileEliteTrainingZoneDefault"
      }
    }
  },
  V2MobileEliteTrainingAdDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      type: { type: "string", description: "Type TODO" },
      url: { type: "string", description: "Url TODO" },
      location: { type: "string", description: "Location TODO" }
    }
  },
  V2MobileEliteTrainingAdDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            type: { type: "string", description: "Type TODO" },
            url: { type: "string", description: "Url TODO" },
            location: { type: "string", description: "Location TODO" }
          }
        },
        ref: "V2MobileEliteTrainingAdDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2MobileEliteTrainingCourseDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      product: {
        description: "Product TODO",
        ref: "V2MobileEliteTrainingProductDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          price: { type: "number", description: "Price TODO" },
          duration: { type: "number", description: "Duration TODO" },
          tagListText: { type: "string", description: "Tag list text TODO" },
          banners: {
            type: "array",
            items: {
              type: "object",
              properties: {
                uid: { type: "number", description: "Uid TODO" },
                signedId: { type: "string", description: "Signed TODO" },
                name: { type: "string", description: "Name TODO" },
                url: { type: "string", description: "Url TODO" },
                originalUrl: {
                  type: "string",
                  description: "Original url TODO"
                },
                contentType: {
                  type: "string",
                  description: "Content type TODO"
                }
              }
            },
            description: "Banners TODO",
            ref: "V2AttachmentDefault"
          },
          entryPrice: { type: "string", description: "Entry price TODO" }
        }
      },
      primaryCoach: {
        description: "Primary coach TODO",
        ref: "V2MobileEliteTrainingCoachDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          },
          displayName: { type: "string", description: "Display name TODO" },
          state: { type: "string", description: "State TODO" },
          price: { type: "number", description: "Price TODO" }
        }
      },
      startTime: {
        description: "Start time TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  V2MobileEliteTrainingBookingDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      course: {
        description: "Course TODO",
        ref: "V2MobileEliteTrainingCourseDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          product: {
            description: "Product TODO",
            ref: "V2MobileEliteTrainingProductDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "Id TODO" },
              name: { type: "string", description: "Name TODO" },
              price: { type: "number", description: "Price TODO" },
              duration: { type: "number", description: "Duration TODO" },
              tagListText: {
                type: "string",
                description: "Tag list text TODO"
              },
              banners: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    uid: { type: "number", description: "Uid TODO" },
                    signedId: { type: "string", description: "Signed TODO" },
                    name: { type: "string", description: "Name TODO" },
                    url: { type: "string", description: "Url TODO" },
                    originalUrl: {
                      type: "string",
                      description: "Original url TODO"
                    },
                    contentType: {
                      type: "string",
                      description: "Content type TODO"
                    }
                  }
                },
                description: "Banners TODO",
                ref: "V2AttachmentDefault"
              },
              entryPrice: { type: "string", description: "Entry price TODO" }
            }
          },
          primaryCoach: {
            description: "Primary coach TODO",
            ref: "V2MobileEliteTrainingCoachDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "Id TODO" },
              avatar: {
                description: "Avatar TODO",
                ref: "V2AttachmentDefault",
                type: "object",
                properties: {
                  uid: { type: "number", description: "Uid TODO" },
                  signedId: { type: "string", description: "Signed TODO" },
                  name: { type: "string", description: "Name TODO" },
                  url: { type: "string", description: "Url TODO" },
                  originalUrl: {
                    type: "string",
                    description: "Original url TODO"
                  },
                  contentType: {
                    type: "string",
                    description: "Content type TODO"
                  }
                }
              },
              displayName: { type: "string", description: "Display name TODO" },
              state: { type: "string", description: "State TODO" },
              price: { type: "number", description: "Price TODO" }
            }
          },
          startTime: {
            description: "Start time TODO",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          }
        }
      },
      account: {
        description: "Account TODO",
        ref: "V2MobileMembershipAccountDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          accountType: { type: "string", description: "Account type TODO" },
          balance: { type: "string", description: "Balance TODO" },
          value: { type: "string", description: "Value TODO" },
          bonus: { type: "string", description: "Bonus TODO" },
          shared: { type: "boolean", description: "Shared TODO" },
          workflowState: { type: "string", description: "Workflow state TODO" },
          expiryDate: {
            description: "Expiry date TODO",
            ref: "datetime",
            type: "string",
            format: "date-time",
            example: "2018-08-01T00:00:00.000Z",
            properties: {}
          },
          coaches: {
            type: "array",
            items: {
              type: "object",
              properties: {
                id: { type: "number", description: "Id TODO" },
                name: { type: "string", description: "Name TODO" },
                displayName: {
                  type: "string",
                  description: "Display name TODO"
                },
                avatar: {
                  description: "Avatar TODO",
                  ref: "V2AttachmentDefault",
                  type: "object",
                  properties: {
                    uid: { type: "number", description: "Uid TODO" },
                    signedId: { type: "string", description: "Signed TODO" },
                    name: { type: "string", description: "Name TODO" },
                    url: { type: "string", description: "Url TODO" },
                    originalUrl: {
                      type: "string",
                      description: "Original url TODO"
                    },
                    contentType: {
                      type: "string",
                      description: "Content type TODO"
                    }
                  }
                }
              }
            },
            description: "Coaches TODO",
            ref: "V2CoachCoachDefault"
          },
          card: {
            description: "Card TODO",
            ref: "V2MobileMembershipCardDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "Id TODO" },
              name: { type: "string", description: "Name TODO" },
              productType: { type: "string", description: "Product type TODO" }
            }
          }
        }
      },
      member: {
        description: "Member TODO",
        ref: "V2CoachMemberDefault",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          realName: { type: "string", description: "Real name TODO" },
          phone: { type: "string", description: "Phone TODO" },
          gender: { type: "string", description: "Gender TODO" },
          height: { type: "number", description: "Height TODO" },
          weight: { type: "number", description: "Weight TODO" },
          dateOfBirth: {
            description: "Date of birth TODO",
            ref: "date",
            type: "string",
            format: "date",
            example: "2018-01-01",
            properties: {}
          },
          age: { type: "number", description: "Age TODO" },
          coach: {
            description: "Coach TODO",
            ref: "V2CoachCoachDefault",
            type: "object",
            properties: {
              id: { type: "number", description: "Id TODO" },
              name: { type: "string", description: "Name TODO" },
              displayName: { type: "string", description: "Display name TODO" },
              avatar: {
                description: "Avatar TODO",
                ref: "V2AttachmentDefault",
                type: "object",
                properties: {
                  uid: { type: "number", description: "Uid TODO" },
                  signedId: { type: "string", description: "Signed TODO" },
                  name: { type: "string", description: "Name TODO" },
                  url: { type: "string", description: "Url TODO" },
                  originalUrl: {
                    type: "string",
                    description: "Original url TODO"
                  },
                  contentType: {
                    type: "string",
                    description: "Content type TODO"
                  }
                }
              }
            }
          },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          },
          tagList: {
            type: "array",
            items: { type: "string" },
            description: "Tag list TODO"
          },
          followed: { type: "boolean", description: "Followed TODO" }
        }
      }
    }
  },
  V2MobileEliteTrainingBookingCreateRequest: {
    type: "object",
    properties: {
      accountId: { type: "number", description: "账户", required: true },
      coachId: { type: "number", description: "Coach TODO", required: true },
      productId: {
        type: "number",
        description: "Product TODO",
        required: true
      },
      zoneId: { type: "number", description: "Zone TODO", required: true },
      startTime: {
        type: "string",
        format: "date-time",
        description: "Start time TODO",
        required: true
      }
    }
  },
  V2MobileCourseBookingCamp: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      member: {
        description: "Member TODO",
        ref: "V2MemberMemberOption",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          phone: { type: "string", description: "Phone TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      workflowState: { type: "string", description: "Workflow state TODO" }
    }
  },
  V2MemberMonthlyReportDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      member: {
        description: "Member TODO",
        ref: "V2MemberMemberOption",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          phone: { type: "string", description: "Phone TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      month: { type: "string", description: "Month TODO" },
      calories: { type: "number", description: "Calories TODO" },
      trainingDuration: {
        type: "number",
        description: "Training duration TODO"
      },
      trainingReportScope: {
        type: "array",
        items: { type: "string" },
        description: "Training report scope TODO"
      },
      trainingCount: { type: "number", description: "Training count TODO" },
      lastMonthTrainingCount: {
        type: "number",
        description: "Last month training count TODO"
      },
      quote: { type: "string", description: "Quote TODO" },
      groupTrainingData: {
        type: "object",
        properties: {
          trainingCount: {
            type: "integer",
            description: "Training count TODO"
          },
          favoriteProduct: {
            type: "object",
            properties: {
              name: { type: "string" },
              enName: { type: "string" },
              count: { type: "integer" }
            }
          },
          favoriteCoach: {
            type: "object",
            properties: {
              coach: { $ref: "#/definitions/V2CoachCoachDefault" },
              duration: { type: "integer" },
              count: { type: "integer" }
            }
          },
          metMembers: {
            type: "array",
            items: {
              member: {
                serializer: "V2::Member::MemberSerializer",
                schema: "option"
              },
              meeting_count: "integer"
            }
          }
        },
        description: "Group training data TODO"
      },
      eliteTrainingData: {
        type: "object",
        properties: {
          trainingCounts: {
            type: "array",
            items: { course_name: "string", count: "integer" }
          },
          coaches: {
            type: "array",
            items: {
              coach: { serializer: "V2::Coach::CoachSerializer" },
              count: "integer"
            }
          },
          trainingCount: {
            type: "integer",
            description: "Training count TODO"
          },
          rankPercentage: { type: "string" }
        },
        description: "Elite training data TODO"
      },
      campData: {
        type: "object",
        properties: {
          trainingCount: {
            type: "integer",
            description: "Training count TODO"
          },
          camps: {
            type: "array",
            items: {
              finished_courses: "integer",
              footprint: { serializer: "Course::MemberFootprintSerializer" },
              booking: {
                serializer: "V2::Mobile::Course::BookingSerializer",
                schema: "camp"
              },
              camp: {
                serializer: "Mobile::Camp::CampSerializer",
                schema: "essential"
              }
            }
          }
        },
        description: "Camp data TODO"
      },
      freeTrainingData: {
        type: "object",
        properties: {
          trainingCount: {
            type: "integer",
            description: "Training count TODO"
          }
        },
        description: "Free training data TODO"
      },
      calendarData: {
        type: "object",
        properties: {},
        description: "Calendar data TODO"
      }
    }
  },
  V2MemberMonthlyReportReport: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      member: {
        description: "Member TODO",
        ref: "V2MemberMemberOption",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          phone: { type: "string", description: "Phone TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      month: { type: "string", description: "Month TODO" },
      calories: { type: "number", description: "Calories TODO" },
      trainingDuration: {
        type: "number",
        description: "Training duration TODO"
      },
      trainingCount: { type: "number", description: "Training count TODO" },
      lastMonthTrainingCount: {
        type: "number",
        description: "Last month training count TODO"
      },
      quote: { type: "string", description: "Quote TODO" },
      trainingRankPercentage: {
        type: "string",
        description: "Training rank percentage TODO"
      },
      calendarData: {
        type: "object",
        properties: {},
        description: "Calendar data TODO"
      }
    }
  },
  V2MemberYearlyReportDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      member: {
        description: "Member TODO",
        ref: "V2MemberMemberOption",
        type: "object",
        properties: {
          id: { type: "number", description: "Id TODO" },
          name: { type: "string", description: "Name TODO" },
          phone: { type: "string", description: "Phone TODO" },
          avatar: {
            description: "Avatar TODO",
            ref: "V2AttachmentDefault",
            type: "object",
            properties: {
              uid: { type: "number", description: "Uid TODO" },
              signedId: { type: "string", description: "Signed TODO" },
              name: { type: "string", description: "Name TODO" },
              url: { type: "string", description: "Url TODO" },
              originalUrl: { type: "string", description: "Original url TODO" },
              contentType: { type: "string", description: "Content type TODO" }
            }
          }
        }
      },
      year: { type: "string", description: "Year TODO" },
      rank: { type: "number", description: "Rank TODO" },
      calories: { type: "number", description: "Calories TODO" },
      trainingDuration: {
        type: "number",
        description: "Training duration TODO"
      },
      trainingReportScope: {
        type: "array",
        items: { type: "string" },
        description: "Training report scope TODO"
      },
      trainingCount: { type: "number", description: "Training count TODO" },
      quote: { type: "string", description: "Quote TODO" },
      groupTrainingData: {
        type: "object",
        properties: {
          trainingCount: {
            type: "integer",
            description: "Training count TODO"
          },
          favoriteProduct: {
            type: "object",
            properties: {
              name: { type: "string" },
              enName: { type: "string" },
              count: { type: "integer" }
            }
          },
          favoriteCoach: {
            type: "object",
            properties: {
              coach: { $ref: "#/definitions/V2CoachCoachDefault" },
              duration: { type: "integer" },
              count: { type: "integer" }
            }
          },
          metMembers: {
            type: "array",
            items: {
              member: {
                serializer: "V2::Member::MemberSerializer",
                schema: "option"
              },
              meeting_count: "integer"
            }
          }
        },
        description: "Group training data TODO"
      },
      eliteTrainingData: {
        type: "object",
        properties: {
          trainingCounts: {
            type: "array",
            items: { course_name: "string", count: "integer" }
          },
          coaches: {
            type: "array",
            items: {
              coach: { serializer: "V2::Coach::CoachSerializer" },
              count: "integer"
            }
          },
          trainingCount: {
            type: "integer",
            description: "Training count TODO"
          },
          rankPercentage: { type: "string" }
        },
        description: "Elite training data TODO"
      },
      campData: {
        type: "object",
        properties: {
          trainingCount: {
            type: "integer",
            description: "Training count TODO"
          },
          camps: {
            type: "array",
            items: {
              finished_courses: "integer",
              footprint: { serializer: "Course::MemberFootprintSerializer" },
              booking: {
                serializer: "V2::Mobile::Course::BookingSerializer",
                schema: "camp"
              },
              camp: {
                serializer: "Mobile::Camp::CampSerializer",
                schema: "essential"
              }
            }
          }
        },
        description: "Camp data TODO"
      }
    }
  },
  V2MobileMemberUpdateRequest: {
    type: "object",
    properties: {
      fitnessTestStartDate: {
        type: "string",
        format: "date",
        description: "Fitness test start date TODO"
      },
      dateOfBirth: { type: "string", format: "date", description: "生日" },
      gender: { type: "string", description: "性别" },
      height: { type: "string", description: "Height TODO" },
      weight: { type: "string", description: "体重" }
    }
  },
  V2MobilePartnerConsumeRequest: {
    type: "object",
    properties: {
      originalAmount: {
        type: "string",
        description: "Original amount TODO",
        required: true
      },
      amount: { type: "string", description: "Amount TODO", required: true },
      partnerProductIds: {
        type: "array",
        description: "Partner product ids TODO"
      }
    }
  },
  BrandCommunicationDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      content: { type: "string", description: "Content TODO" },
      createdAt: {
        description: "Created at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  V2PlatformCommunicationCreateRequest: {
    type: "object",
    properties: {
      content: { type: "string", description: "Content TODO", required: true }
    }
  },
  PlatformBrandOutlinePermissionOption: {
    type: "object",
    properties: { id: { type: "number", description: "ID" } }
  },
  PlatformBrandOutlinePermissionDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      brandId: { type: "number", description: "Brand TODO" },
      outlineName: { type: "string", description: "课程套路" },
      packageName: { type: "string", description: "课程包" },
      actorName: { type: "string", description: "授权人" },
      note: { type: "string", description: "授权备注" },
      createdAt: {
        description: "授权时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  PlatformBrandOutlinePermissionDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            brandId: { type: "number", description: "Brand TODO" },
            outlineName: { type: "string", description: "课程套路" },
            packageName: { type: "string", description: "课程包" },
            actorName: { type: "string", description: "授权人" },
            note: { type: "string", description: "授权备注" },
            createdAt: {
              description: "授权时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        ref: "PlatformBrandOutlinePermissionDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2PlatformBrandOutlinePermissionCreateRequest: {
    type: "object",
    properties: {
      brandId: { type: "number", description: "Brand TODO", required: true },
      courseProductId: {
        type: "number",
        description: "Course product TODO",
        required: true
      },
      allWorkoutOutlines: {
        type: "boolean",
        description: "授权课程包全部套路"
      },
      note: { type: "string", description: "授权备注" },
      packageId: { type: "number", description: "课程包" },
      workoutOutlineIds: { type: "array", description: "课程套路" }
    }
  },
  CoachCommunicationOption: {
    type: "object",
    properties: { id: { type: "number", description: "Id TODO" } }
  },
  CoachCommunicationDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      content: { type: "string", description: "Content TODO" },
      createdAt: {
        description: "Created at TODO",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  CoachCommunicationOptionPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: { id: { type: "number", description: "Id TODO" } }
        },
        ref: "CoachCommunicationOption"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  CoachCommunicationDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            content: { type: "string", description: "Content TODO" },
            createdAt: {
              description: "Created at TODO",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        ref: "CoachCommunicationDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2PlatformCoachCommunicationCreateRequest: {
    type: "object",
    properties: {
      content: { type: "string", description: "Content TODO", required: true }
    }
  },
  PlatformCoachOutlinePermissionOption: {
    type: "object",
    properties: { id: { type: "number", description: "ID" } }
  },
  PlatformCoachOutlinePermissionDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      coachId: { type: "number", description: "Coach TODO" },
      outlineName: { type: "string", description: "课程套路" },
      packageName: { type: "string", description: "课程包" },
      actorName: { type: "string", description: "授权人" },
      note: { type: "string", description: "授权备注" },
      createdAt: {
        description: "授权时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  PlatformCoachOutlinePermissionDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            coachId: { type: "number", description: "Coach TODO" },
            outlineName: { type: "string", description: "课程套路" },
            packageName: { type: "string", description: "课程包" },
            actorName: { type: "string", description: "授权人" },
            note: { type: "string", description: "授权备注" },
            createdAt: {
              description: "授权时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        ref: "PlatformCoachOutlinePermissionDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2PlatformCoachOutlinePermissionCreateRequest: {
    type: "object",
    properties: {
      coachId: { type: "number", description: "Coach TODO", required: true },
      coachProductId: {
        type: "number",
        description: "Coach product TODO",
        required: true
      },
      allWorkoutOutlines: {
        type: "boolean",
        description: "授权课程包全部套路"
      },
      note: { type: "string", description: "授权备注" },
      packageId: { type: "number", description: "课程包" },
      workoutOutlineIds: { type: "array", description: "课程套路" }
    }
  },
  V2PlatformCoachOutlinePermissionUpdateRequest: {
    type: "object",
    properties: {
      coachId: { type: "number", description: "Coach TODO" },
      coachProductId: { type: "number", description: "Coach product TODO" },
      workoutOutlineId: { type: "number", description: "Workout outline TODO" },
      actorType: { type: "string", description: "Actor type TODO" },
      actorId: { type: "number", description: "Actor TODO" },
      note: { type: "string", description: "授权备注" }
    }
  },
  PlatformCoachWorkoutVerificationOption: {
    type: "object",
    properties: { id: { type: "number", description: "ID" } }
  },
  PlatformCoachWorkoutVerificationDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      workoutName: { type: "string", description: "认证课程" },
      coachId: { type: "number", description: "Coach TODO" },
      verifiedAt: {
        description: "认证时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      },
      note: { type: "string", description: "备注" }
    }
  },
  PlatformCoachWorkoutVerificationDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            workoutName: { type: "string", description: "认证课程" },
            coachId: { type: "number", description: "Coach TODO" },
            verifiedAt: {
              description: "认证时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            },
            note: { type: "string", description: "备注" }
          }
        },
        ref: "PlatformCoachWorkoutVerificationDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2PlatformCoachWorkoutVerificationCreateRequest: {
    type: "object",
    properties: {
      workoutId: { type: "number", description: "认证课程", required: true },
      verifiedAt: {
        type: "string",
        format: "date-time",
        description: "认证时间",
        required: true
      },
      note: { type: "string", description: "备注" }
    }
  },
  V2PlatformCoachWorkoutVerificationUpdateRequest: {
    type: "object",
    properties: {
      workoutId: { type: "number", description: "认证课程" },
      coachId: { type: "number", description: "Coach TODO" },
      verifiedAt: {
        type: "string",
        format: "date-time",
        description: "认证时间"
      },
      note: { type: "string", description: "备注" }
    }
  },
  NotificationOption: {
    type: "object",
    properties: { id: { type: "number", description: "ID" } }
  },
  NotificationDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      title: { type: "string", description: "标题" },
      content: { type: "string", description: "内容" },
      forceRead: { type: "boolean", description: "弹窗" },
      serviceProductList: {
        type: "array",
        items: { type: "string" },
        description: "产品类型"
      },
      createdAt: {
        description: "发布时间",
        ref: "datetime",
        type: "string",
        format: "date-time",
        example: "2018-08-01T00:00:00.000Z",
        properties: {}
      }
    }
  },
  NotificationDefaultPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            title: { type: "string", description: "标题" },
            content: { type: "string", description: "内容" },
            forceRead: { type: "boolean", description: "弹窗" },
            serviceProductList: {
              type: "array",
              items: { type: "string" },
              description: "产品类型"
            },
            createdAt: {
              description: "发布时间",
              ref: "datetime",
              type: "string",
              format: "date-time",
              example: "2018-08-01T00:00:00.000Z",
              properties: {}
            }
          }
        },
        ref: "NotificationDefault"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2PlatformNotificationCreateRequest: {
    type: "object",
    properties: {
      title: { type: "string", description: "标题", required: true },
      content: { type: "string", description: "内容", required: true },
      forceRead: { type: "boolean", description: "弹窗", required: true },
      serviceProductList: {
        type: "array",
        description: "产品类型",
        required: true
      }
    }
  },
  V2PlatformNotificationUpdateRequest: {
    type: "object",
    properties: {
      title: { type: "string", description: "标题", required: true },
      content: { type: "string", description: "内容", required: true },
      forceRead: { type: "boolean", description: "弹窗", required: true },
      serviceProductList: {
        type: "array",
        description: "产品类型",
        required: true
      }
    }
  },
  V2PlatformBrandBrandDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" }
    }
  },
  V2PlatformBrandBrandOperating: {
    type: "object",
    properties: {
      id: { type: "number", description: "ID" },
      name: { type: "string", description: "名称" },
      serviceProductList: {
        type: "array",
        items: { type: "string" },
        description: "产品"
      },
      contactName: { type: "string", required: true, description: "联系人" },
      contactPhone: { type: "string", required: true, description: "联系电话" },
      expiryDate: {
        description: "到期时间",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      operatingTagList: {
        type: "array",
        items: { type: "string" },
        description: "运营标签"
      },
      industryList: {
        type: "array",
        items: { type: "string" },
        description: "行业"
      },
      provinceAndCity: { type: "string", description: "省/市" },
      companyName: { type: "string", description: "公司名称" },
      companyAddress: { type: "string", description: "公司地址" },
      province: { type: "string", description: "省" },
      city: { type: "string", description: "城市" },
      studiosCount: { type: "number", description: "门店数" },
      orderAmount: { type: "number", description: "销售总金额" },
      settlementAmount: { type: "number", description: "收入总金额" },
      activeMemberCount: { type: "number", description: "活跃用户数" },
      operatingAlertList: {
        type: "array",
        items: { type: "string" },
        description: "服务预警"
      }
    }
  },
  V2PlatformBrandBrandOperatingPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "ID" },
            name: { type: "string", description: "名称" },
            serviceProductList: {
              type: "array",
              items: { type: "string" },
              description: "产品"
            },
            contactName: {
              type: "string",
              required: true,
              description: "联系人"
            },
            contactPhone: {
              type: "string",
              required: true,
              description: "联系电话"
            },
            expiryDate: {
              description: "到期时间",
              ref: "date",
              type: "string",
              format: "date",
              example: "2018-01-01",
              properties: {}
            },
            operatingTagList: {
              type: "array",
              items: { type: "string" },
              description: "运营标签"
            },
            industryList: {
              type: "array",
              items: { type: "string" },
              description: "行业"
            },
            provinceAndCity: { type: "string", description: "省/市" },
            companyName: { type: "string", description: "公司名称" },
            companyAddress: { type: "string", description: "公司地址" },
            province: { type: "string", description: "省" },
            city: { type: "string", description: "城市" },
            studiosCount: { type: "number", description: "门店数" },
            orderAmount: { type: "number", description: "销售总金额" },
            settlementAmount: { type: "number", description: "收入总金额" },
            activeMemberCount: { type: "number", description: "活跃用户数" },
            operatingAlertList: {
              type: "array",
              items: { type: "string" },
              description: "服务预警"
            }
          }
        },
        ref: "V2PlatformBrandBrandOperating"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  },
  V2PlatformOperatingBrandUpdateRequest: {
    type: "object",
    properties: {
      serviceProductList: { type: "array", description: "产品" },
      operatingTagList: { type: "array", description: "运营标签" },
      industryList: { type: "array", description: "行业" },
      provinceAndCity: { type: "array", description: "省/市" },
      contactName: { type: "string", description: "联系人" },
      contactPhone: { type: "string", description: "联系电话" },
      expiryDate: { type: "string", format: "date", description: "到期时间" },
      companyName: { type: "string", description: "公司名称" },
      companyAddress: { type: "string", description: "公司地址" }
    }
  },
  V2PlatformCourseProductOption: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" }
    }
  },
  V2PlatformCourseProductDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      brandId: { type: "number", description: "Brand TODO" },
      workoutId: { type: "number", description: "Workout TODO" },
      name: { type: "string", description: "Name TODO" },
      intro: { type: "string", description: "Intro TODO" },
      productType: { type: "string", description: "Product type TODO" },
      price: { type: "string", description: "Price TODO" },
      active: { type: "boolean", description: "Active TODO" },
      duration: { type: "number", description: "Duration TODO" },
      enName: { type: "string", description: "En name TODO" },
      category: { type: "string", description: "Category TODO" },
      expiryDate: {
        description: "Expiry date TODO",
        ref: "date",
        type: "string",
        format: "date",
        example: "2018-01-01",
        properties: {}
      },
      courseFee: { type: "string", description: "Course fee TODO" },
      assistantCourseFee: {
        type: "string",
        description: "Assistant course fee TODO"
      },
      trialCourseFee: { type: "string", description: "Trial course fee TODO" },
      academy: { type: "boolean", description: "Academy TODO" },
      paymentMethods: { type: "string", description: "Payment methods TODO" },
      defaultCaloriesTarget: {
        type: "number",
        description: "Default calories target TODO"
      },
      tasType: { type: "string", description: "Tas type TODO" },
      multiScreenVersions: {
        type: "string",
        description: "Multi screen versions TODO"
      },
      provider: { type: "string", description: "Provider TODO" },
      useHrm: { type: "boolean", description: "Use hrm TODO" },
      forKid: { type: "boolean", description: "For kid TODO" },
      displayed: { type: "boolean", description: "Displayed TODO" },
      tagListText: { type: "string", description: "Tag list text TODO" },
      scheduleRule: { type: "string", description: "Schedule rule TODO" },
      position: { type: "number", description: "Position TODO" },
      ktasWorkoutId: { type: "number", description: "Ktas workout TODO" },
      groundType: { type: "string", description: "Ground type TODO" },
      confirmCourseThreshold: {
        type: "number",
        description: "Confirm course threshold TODO"
      },
      confirmCourseDeadline: {
        type: "number",
        description: "Confirm course deadline TODO"
      },
      enableWod: { type: "boolean", description: "Enable wod TODO" },
      showWodOnCourseDate: {
        type: "boolean",
        description: "Show wod on course date TODO"
      },
      customIntro: { type: "boolean", description: "Custom intro TODO" }
    }
  },
  V2PlatformTasPackageDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      serial: { type: "string", description: "Serial TODO" },
      active: { type: "boolean", description: "Active TODO" },
      name: { type: "string", description: "Name TODO" }
    }
  },
  V2PlatformTasWorkoutOutlineDefault: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      serial: { type: "string", description: "Serial TODO" },
      category: { type: "string", description: "Category TODO" },
      workflowState: { type: "string", description: "Workflow state TODO" },
      packageId: { type: "number", description: "Package TODO" },
      note: { type: "string", description: "Note TODO" },
      active: { type: "boolean", description: "Active TODO" }
    }
  },
  V2PlatformCourseProductOperating: {
    type: "object",
    properties: {
      id: { type: "number", description: "Id TODO" },
      name: { type: "string", description: "Name TODO" },
      packages: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            serial: { type: "string", description: "Serial TODO" },
            active: { type: "boolean", description: "Active TODO" },
            name: { type: "string", description: "Name TODO" }
          }
        },
        description: "Packages TODO",
        ref: "V2PlatformTasPackageDefault"
      },
      workoutOutlines: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            serial: { type: "string", description: "Serial TODO" },
            category: { type: "string", description: "Category TODO" },
            workflowState: {
              type: "string",
              description: "Workflow state TODO"
            },
            packageId: { type: "number", description: "Package TODO" },
            note: { type: "string", description: "Note TODO" },
            active: { type: "boolean", description: "Active TODO" }
          }
        },
        description: "Workout outlines TODO",
        ref: "V2PlatformTasWorkoutOutlineDefault"
      }
    }
  },
  V2PlatformCourseProductOperatingPagedArray: {
    type: "object",
    properties: {
      data: {
        type: "array",
        items: {
          type: "object",
          properties: {
            id: { type: "number", description: "Id TODO" },
            name: { type: "string", description: "Name TODO" },
            packages: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "Id TODO" },
                  serial: { type: "string", description: "Serial TODO" },
                  active: { type: "boolean", description: "Active TODO" },
                  name: { type: "string", description: "Name TODO" }
                }
              },
              description: "Packages TODO",
              ref: "V2PlatformTasPackageDefault"
            },
            workoutOutlines: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  id: { type: "number", description: "Id TODO" },
                  serial: { type: "string", description: "Serial TODO" },
                  category: { type: "string", description: "Category TODO" },
                  workflowState: {
                    type: "string",
                    description: "Workflow state TODO"
                  },
                  packageId: { type: "number", description: "Package TODO" },
                  note: { type: "string", description: "Note TODO" },
                  active: { type: "boolean", description: "Active TODO" }
                }
              },
              description: "Workout outlines TODO",
              ref: "V2PlatformTasWorkoutOutlineDefault"
            }
          }
        },
        ref: "V2PlatformCourseProductOperating"
      },
      pagination: {
        ref: "Pagination",
        type: "object",
        properties: {
          currentPage: { type: "number" },
          nextPage: { type: "number", nullable: true },
          prevPage: { type: "number", nullable: true },
          totalPages: { type: "number" },
          totalCount: { type: "number" }
        }
      }
    }
  }
};
const pathRawData = {
  v2BrandBrandConnectionIndex: {
    method: "get",
    url: "/v2/brand/brand/connections/",
    operationId: "v2BrandBrandConnectionIndex",
    summary: "关联品牌列表",
    tags: "V2#Brand#Brand#Connection",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: { $ref: "BrandConnectionDefaultPagedArray", type: "object" }
  },
  v2BrandBrandConnectionCreate: {
    method: "post",
    url: "/v2/brand/brand/connections/",
    operationId: "v2BrandBrandConnectionCreate",
    summary: "新建关联品牌",
    tags: "V2#Brand#Brand#Connection",
    parameters: {
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandBrandConnectionCreateRequest" }
        }
      }
    },
    responses: { $ref: "BrandConnectionDefault", type: "object" }
  },
  v2BrandBrandConnectionShow: {
    method: "get",
    url: "/v2/brand/brand/connections/{id}",
    operationId: "v2BrandBrandConnectionShow",
    summary: "关联品牌详情",
    tags: "V2#Brand#Brand#Connection",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "ID",
          required: true
        }
      }
    },
    responses: { $ref: "BrandConnectionDefault", type: "object" }
  },
  v2BrandBrandConnectionUpdate: {
    method: "put",
    url: "/v2/brand/brand/connections/{id}",
    operationId: "v2BrandBrandConnectionUpdate",
    summary: "编辑关联品牌",
    tags: "V2#Brand#Brand#Connection",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandBrandConnectionUpdateRequest" }
        }
      }
    },
    responses: { $ref: "BrandConnectionDefault", type: "object" }
  },
  v2BrandBrandConnectionDestroy: {
    method: "delete",
    url: "/v2/brand/brand/connections/{id}",
    operationId: "v2BrandBrandConnectionDestroy",
    summary: "删除关联品牌",
    tags: "V2#Brand#Brand#Connection",
    parameters: {
      path: { id: { name: "id", in: "path", type: "integer", required: true } }
    },
    responses: null
  },
  v2BrandBrandConnectionMoveUp: {
    method: "patch",
    url: "/v2/brand/brand/connections/{connectionId}/move_up",
    operationId: "v2BrandBrandConnectionMoveUp",
    summary: "上移关联品牌",
    tags: "V2#Brand#Brand#Connection",
    parameters: {
      path: {
        connectionId: {
          name: "connectionId",
          in: "path",
          type: "integer",
          description: "connection_id",
          required: true
        }
      }
    },
    responses: { $ref: "BrandConnectionDefault", type: "object" }
  },
  v2BrandBrandFitnessTestMetricOptions: {
    method: "get",
    url: "/v2/brand/brand/fitness_test_metrics/options",
    operationId: "v2BrandBrandFitnessTestMetricOptions",
    summary: "体测指标选项列表",
    tags: "V2#Brand#Brand#FitnessTestMetric",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "激活"
        },
        ids: { name: "ids", in: "query", type: "array", description: "ids" }
      }
    },
    responses: {
      $ref: "BrandFitnessTestMetricOptionPagedArray",
      type: "object"
    }
  },
  v2BrandBrandFitnessTestMetricIndex: {
    method: "get",
    url: "/v2/brand/brand/fitness_test_metrics/",
    operationId: "v2BrandBrandFitnessTestMetricIndex",
    summary: "体测指标列表",
    tags: "V2#Brand#Brand#FitnessTestMetric",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "激活"
        }
      }
    },
    responses: {
      $ref: "BrandFitnessTestMetricDefaultPagedArray",
      type: "object"
    }
  },
  v2BrandBrandFitnessTestMetricCreate: {
    method: "post",
    url: "/v2/brand/brand/fitness_test_metrics/",
    operationId: "v2BrandBrandFitnessTestMetricCreate",
    summary: "新建体测指标",
    tags: "V2#Brand#Brand#FitnessTestMetric",
    parameters: {
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandBrandFitnessTestMetricCreateRequest" }
        }
      }
    },
    responses: { $ref: "BrandFitnessTestMetricDefault", type: "object" }
  },
  v2BrandBrandFitnessTestMetricShow: {
    method: "get",
    url: "/v2/brand/brand/fitness_test_metrics/{id}",
    operationId: "v2BrandBrandFitnessTestMetricShow",
    summary: "体测指标详情",
    tags: "V2#Brand#Brand#FitnessTestMetric",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "ID",
          required: true
        }
      }
    },
    responses: { $ref: "BrandFitnessTestMetricDefault", type: "object" }
  },
  v2BrandBrandFitnessTestMetricUpdate: {
    method: "put",
    url: "/v2/brand/brand/fitness_test_metrics/{id}",
    operationId: "v2BrandBrandFitnessTestMetricUpdate",
    summary: "编辑体测指标",
    tags: "V2#Brand#Brand#FitnessTestMetric",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandBrandFitnessTestMetricUpdateRequest" }
        }
      }
    },
    responses: { $ref: "BrandFitnessTestMetricDefault", type: "object" }
  },
  v2BrandBrandFitnessTestMetricMoveUp: {
    method: "patch",
    url: "/v2/brand/brand/fitness_test_metrics/{fitnessTestMetricId}/move_up",
    operationId: "v2BrandBrandFitnessTestMetricMoveUp",
    summary: "上移体测指标",
    tags: "V2#Brand#Brand#FitnessTestMetric",
    parameters: {
      path: {
        fitnessTestMetricId: {
          name: "fitnessTestMetricId",
          in: "path",
          type: "integer",
          description: "fitness_test_metric_id",
          required: true
        }
      }
    },
    responses: { $ref: "BrandFitnessTestMetricDefault", type: "object" }
  },
  v2BrandBrandMpTabIndex: {
    method: "get",
    url: "/v2/brand/brand/mp_tabs/",
    operationId: "v2BrandBrandMpTabIndex",
    summary: "小程序菜单列表",
    tags: "V2#Brand#Brand#MpTab",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "激活"
        }
      }
    },
    responses: { $ref: "BrandMpTabDefaultPagedArray", type: "object" }
  },
  v2BrandBrandMpTabCreate: {
    method: "post",
    url: "/v2/brand/brand/mp_tabs",
    operationId: "v2BrandBrandMpTabCreate",
    summary: "新建小程序菜单",
    tags: "V2#Brand#Brand#MpTab",
    parameters: {
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandBrandMpTabCreateRequest" }
        }
      }
    },
    responses: { $ref: "BrandMpTabDefault", type: "object" }
  },
  v2BrandBrandMpTabShow: {
    method: "get",
    url: "/v2/brand/brand/mp_tabs/{id}",
    operationId: "v2BrandBrandMpTabShow",
    summary: "小程序菜单详情",
    tags: "V2#Brand#Brand#MpTab",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "ID",
          required: true
        }
      }
    },
    responses: { $ref: "BrandMpTabDefault", type: "object" }
  },
  v2BrandBrandMpTabUpdate: {
    method: "put",
    url: "/v2/brand/brand/mp_tabs/{id}",
    operationId: "v2BrandBrandMpTabUpdate",
    summary: "编辑小程序菜单",
    tags: "V2#Brand#Brand#MpTab",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandBrandMpTabUpdateRequest" }
        }
      }
    },
    responses: { $ref: "BrandMpTabDefault", type: "object" }
  },
  v2BrandBrandMpTabDestroy: {
    method: "delete",
    url: "/v2/brand/brand/mp_tabs/{id}",
    operationId: "v2BrandBrandMpTabDestroy",
    summary: "删除小程序菜单",
    tags: "V2#Brand#Brand#MpTab",
    parameters: {
      path: { id: { name: "id", in: "path", type: "integer", required: true } }
    },
    responses: null
  },
  v2BrandBrandMpTabMoveUp: {
    method: "patch",
    url: "/v2/brand/brand/mp_tabs/{mpTabId}/move_up",
    operationId: "v2BrandBrandMpTabMoveUp",
    summary: "上移小程序菜单",
    tags: "V2#Brand#Brand#MpTab",
    parameters: {
      path: {
        mpTabId: {
          name: "mpTabId",
          in: "path",
          type: "integer",
          description: "mp_tab_id",
          required: true
        }
      }
    },
    responses: { $ref: "BrandMpTabDefault", type: "object" }
  },
  v2BrandCourseCourseCancellation: {
    method: "get",
    url: "/v2/brand/course/courses/cancellation",
    operationId: "v2BrandCourseCourseCancellation",
    summary: "排课取消记录",
    tags: "V2#Brand#Course#Course",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        cancellationType: {
          name: "cancellationType",
          in: "query",
          type: "string",
          description: "Cancellation type TODO"
        },
        from: {
          name: "from",
          in: "query",
          type: "string",
          format: "date",
          description: "from"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          format: "date",
          description: "to"
        },
        productType: {
          name: "productType",
          in: "query",
          type: "string",
          description: "product_type"
        },
        studioId: {
          name: "studioId",
          in: "query",
          type: "integer",
          description: "studio_id"
        }
      }
    },
    responses: {
      $ref: "V2BrandCourseCourseCancellationPagedArray",
      type: "object"
    }
  },
  v2BrandEliteMemberActive: {
    method: "get",
    url: "/v2/brand/elite/members/active",
    operationId: "v2BrandEliteMemberActive",
    summary: "活跃私教会员",
    tags: "V2#Brand#Elite#Member",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "query"
        },
        tag: { name: "tag", in: "query", type: "string", description: "tag" },
        studioId: {
          name: "studioId",
          in: "query",
          type: "integer",
          description: "studio_id"
        },
        duration: {
          name: "duration",
          in: "query",
          type: "array",
          description: "duration"
        }
      }
    },
    responses: { $ref: "V2BrandEliteMemberActivePagedArray", type: "object" }
  },
  v2BrandEliteMemberInactive: {
    method: "get",
    url: "/v2/brand/elite/members/inactive",
    operationId: "v2BrandEliteMemberInactive",
    summary: "不活跃私教会员",
    tags: "V2#Brand#Elite#Member",
    parameters: {
      query: {
        studioId: {
          name: "studioId",
          in: "query",
          type: "integer",
          description: "studio_id"
        },
        tag: { name: "tag", in: "query", type: "string", description: "tag" },
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "query"
        },
        tags: { name: "tags", in: "query", type: "array", description: "tags" }
      }
    },
    responses: { $ref: "V2BrandEliteMemberInactive", type: "array" }
  },
  v2BrandEliteMemberChruned: {
    method: "get",
    url: "/v2/brand/elite/members/chruned",
    operationId: "v2BrandEliteMemberChruned",
    summary: "流失私教会员",
    tags: "V2#Brand#Elite#Member",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        studioId: {
          name: "studioId",
          in: "query",
          type: "integer",
          description: "studio_id"
        },
        tag: { name: "tag", in: "query", type: "string", description: "tag" },
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "query"
        },
        tags: { name: "tags", in: "query", type: "array", description: "tags" }
      }
    },
    responses: { $ref: "V2BrandEliteMemberChrunedPagedArray", type: "object" }
  },
  v2BrandEliteMemberAll: {
    method: "get",
    url: "/v2/brand/elite/members/all",
    operationId: "v2BrandEliteMemberAll",
    summary: "全部私教会员",
    tags: "V2#Brand#Elite#Member",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "query"
        },
        tag: { name: "tag", in: "query", type: "string", description: "tag" },
        tags: { name: "tags", in: "query", type: "array", description: "tags" }
      }
    },
    responses: { $ref: "V2BrandEliteMemberAllPagedArray", type: "object" }
  },
  v2BrandEliteMemberUnassigned: {
    method: "get",
    url: "/v2/brand/elite/members/unassigned",
    operationId: "v2BrandEliteMemberUnassigned",
    summary: "责任教练分配",
    tags: "V2#Brand#Elite#Member",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "query"
        },
        tag: { name: "tag", in: "query", type: "string", description: "tag" }
      }
    },
    responses: {
      $ref: "V2BrandEliteMemberUnassignedPagedArray",
      type: "object"
    }
  },
  v2BrandFitnessTestRecordIndex: {
    method: "get",
    url: "/v2/brand/fitness_test/records/",
    operationId: "v2BrandFitnessTestRecordIndex",
    summary: "体测数据列表",
    tags: "V2#Brand#FitnessTest#Record",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        memberId: {
          name: "memberId",
          in: "query",
          type: "integer",
          description: "Member TODO",
          required: true
        }
      }
    },
    responses: { $ref: "V2FitnessTestRecordDefaultPagedArray", type: "object" }
  },
  v2BrandFitnessTestRecordCreate: {
    method: "post",
    url: "/v2/brand/fitness_test/records/",
    operationId: "v2BrandFitnessTestRecordCreate",
    summary: "新建体测数据",
    tags: "V2#Brand#FitnessTest#Record",
    parameters: {
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandFitnessTestRecordCreateRequest" }
        }
      }
    },
    responses: { $ref: "V2FitnessTestRecordDefault", type: "object" }
  },
  v2BrandFitnessTestRecordShow: {
    method: "get",
    url: "/v2/brand/fitness_test/records/{id}",
    operationId: "v2BrandFitnessTestRecordShow",
    summary: "体测数据详情",
    tags: "V2#Brand#FitnessTest#Record",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "id",
          required: true
        }
      }
    },
    responses: { $ref: "V2FitnessTestRecordDetail", type: "object" }
  },
  v2BrandFitnessTestRecordDestroy: {
    method: "delete",
    url: "/v2/brand/fitness_test/records/{id}",
    operationId: "v2BrandFitnessTestRecordDestroy",
    summary: "删除体测数据",
    tags: "V2#Brand#FitnessTest#Record",
    parameters: {
      path: { id: { name: "id", in: "path", type: "integer", required: true } }
    },
    responses: null
  },
  v2BrandMemberCoachRecordIndex: {
    method: "get",
    url: "/v2/brand/members/{memberId}/coach_records/",
    operationId: "v2BrandMemberCoachRecordIndex",
    summary: "责任教练记录列表",
    tags: "V2#Brand#Member#CoachRecord",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      },
      path: {
        memberId: {
          name: "memberId",
          in: "path",
          type: "integer",
          description: "member_id",
          required: true
        }
      }
    },
    responses: { $ref: "V2MemberCoachRecordDefaultPagedArray", type: "object" }
  },
  v2BrandMembershipAccountLogIndex: {
    method: "get",
    url: "/v2/brand/membership/account_logs/",
    operationId: "v2BrandMembershipAccountLogIndex",
    summary: "账户操作记录列表",
    tags: "V2#Brand#Membership#AccountLog",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "会员姓名或手机号"
        }
      }
    },
    responses: {
      $ref: "MembershipAccountLogDefaultPagedArray",
      type: "object"
    }
  },
  v2BrandNotificationIndex: {
    method: "get",
    url: "/v2/brand/notifications/",
    operationId: "v2BrandNotificationIndex",
    summary: "通知列表",
    tags: "V2#Brand#Notification",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      }
    },
    responses: {
      $ref: "BrandAdminNotificationDefaultPagedArray",
      type: "object"
    }
  },
  v2BrandNotificationShow: {
    method: "get",
    url: "/v2/brand/notifications/{id}",
    operationId: "v2BrandNotificationShow",
    summary: "通知详情",
    tags: "V2#Brand#Notification",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "ID",
          required: true
        }
      }
    },
    responses: { $ref: "BrandAdminNotificationDefault", type: "object" }
  },
  v2BrandNotificationInbox: {
    method: "get",
    url: "/v2/brand/notifications/inbox",
    operationId: "v2BrandNotificationInbox",
    summary: "通知收件箱",
    tags: "V2#Brand#Notification",
    parameters: null,
    responses: { $ref: "BrandAdminNotificationInbox", type: "object" }
  },
  v2BrandNotificationRead: {
    method: "put",
    url: "/v2/brand/notifications/{notificationId}/read",
    operationId: "v2BrandNotificationRead",
    summary: "标记通知为已读",
    tags: "V2#Brand#Notification",
    parameters: {
      path: {
        notificationId: {
          name: "notificationId",
          in: "path",
          type: "integer",
          required: true
        }
      }
    },
    responses: null
  },
  v2BrandOperatingAccountQueryAccounts: {
    method: "get",
    url: "/v2/brand/operating/accounts",
    operationId: "v2BrandOperatingAccountQueryAccounts",
    summary: "会员卡账户查询",
    tags: "V2#Brand#Operating#Account",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        type: {
          name: "type",
          in: "query",
          type: "string",
          description: "type",
          enum: ["prepay", "other"],
          "x-i18n": { prepay: "Prepay TODO", other: "Other TODO" },
          required: true
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "query"
        },
        productType: {
          name: "productType",
          in: "query",
          type: "string",
          description: "product_type",
          enum: ["elite_training", "group_training", "free_training"],
          "x-i18n": {
            elite_training: "Elite training TODO",
            group_training: "Group training TODO",
            free_training: "Free training TODO"
          }
        },
        cardType: {
          name: "cardType",
          in: "query",
          type: "string",
          description: "card_type",
          enum: ["type", "required", "enum", "x-i18n", "description"],
          "x-i18n": {
            type: "Type TODO",
            required: "Required TODO",
            enum: "Enum TODO",
            "x-i18n": "X-i18n TODO",
            description: "Description TODO"
          }
        },
        cardIds: {
          name: "cardIds",
          in: "query",
          type: "array",
          description: "card_ids"
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandOperatingAccountQueryAccountsRequest" }
        }
      }
    },
    responses: {
      $ref: "V2BrandMembershipAccountOperatingPagedArray",
      type: "object"
    }
  },
  v2BrandOperatingAccountPatchActivate: {
    method: "patch",
    url: "/v2/brand/operating/accounts/patch_activate",
    operationId: "v2BrandOperatingAccountPatchActivate",
    summary: "批量开卡",
    tags: "V2#Brand#Operating#Account",
    parameters: {
      query: {
        type: {
          name: "type",
          in: "query",
          type: "string",
          description: "type",
          enum: ["prepay", "other"],
          "x-i18n": { prepay: "Prepay TODO", other: "Other TODO" },
          required: true
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "query"
        },
        productType: {
          name: "productType",
          in: "query",
          type: "string",
          description: "product_type",
          enum: ["elite_training", "group_training", "free_training"],
          "x-i18n": {
            elite_training: "Elite training TODO",
            group_training: "Group training TODO",
            free_training: "Free training TODO"
          }
        },
        cardType: {
          name: "cardType",
          in: "query",
          type: "string",
          description: "card_type",
          enum: ["type", "required", "enum", "x-i18n", "description"],
          "x-i18n": {
            type: "Type TODO",
            required: "Required TODO",
            enum: "Enum TODO",
            "x-i18n": "X-i18n TODO",
            description: "Description TODO"
          }
        },
        cardIds: {
          name: "cardIds",
          in: "query",
          type: "array",
          description: "card_ids"
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandOperatingAccountPatchActivateRequest" }
        }
      }
    },
    responses: { $ref: "MembershipAccountDefault", type: "object" }
  },
  v2BrandOperatingAccountPatchExtendValidityPeriod: {
    method: "patch",
    url: "/v2/brand/operating/accounts/patch_extend_validity_period",
    operationId: "v2BrandOperatingAccountPatchExtendValidityPeriod",
    summary: "批量延期",
    tags: "V2#Brand#Operating#Account",
    parameters: {
      query: {
        type: {
          name: "type",
          in: "query",
          type: "string",
          description: "type",
          enum: ["prepay", "other"],
          "x-i18n": { prepay: "Prepay TODO", other: "Other TODO" },
          required: true
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "query"
        },
        productType: {
          name: "productType",
          in: "query",
          type: "string",
          description: "product_type",
          enum: ["elite_training", "group_training", "free_training"],
          "x-i18n": {
            elite_training: "Elite training TODO",
            group_training: "Group training TODO",
            free_training: "Free training TODO"
          }
        },
        cardType: {
          name: "cardType",
          in: "query",
          type: "string",
          description: "card_type",
          enum: ["type", "required", "enum", "x-i18n", "description"],
          "x-i18n": {
            type: "Type TODO",
            required: "Required TODO",
            enum: "Enum TODO",
            "x-i18n": "X-i18n TODO",
            description: "Description TODO"
          }
        },
        cardIds: {
          name: "cardIds",
          in: "query",
          type: "array",
          description: "card_ids"
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: {
            $ref: "V2BrandOperatingAccountPatchExtendValidityPeriodRequest"
          }
        }
      }
    },
    responses: { $ref: "MembershipAccountDefault", type: "object" }
  },
  v2BrandOperatingAccountPatchPause: {
    method: "patch",
    url: "/v2/brand/operating/accounts/patch_pause",
    operationId: "v2BrandOperatingAccountPatchPause",
    summary: "批量暂停卡",
    tags: "V2#Brand#Operating#Account",
    parameters: {
      query: {
        type: {
          name: "type",
          in: "query",
          type: "string",
          description: "type",
          enum: ["prepay", "other"],
          "x-i18n": { prepay: "Prepay TODO", other: "Other TODO" },
          required: true
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "query"
        },
        productType: {
          name: "productType",
          in: "query",
          type: "string",
          description: "product_type",
          enum: ["elite_training", "group_training", "free_training"],
          "x-i18n": {
            elite_training: "Elite training TODO",
            group_training: "Group training TODO",
            free_training: "Free training TODO"
          }
        },
        cardType: {
          name: "cardType",
          in: "query",
          type: "string",
          description: "card_type",
          enum: ["type", "required", "enum", "x-i18n", "description"],
          "x-i18n": {
            type: "Type TODO",
            required: "Required TODO",
            enum: "Enum TODO",
            "x-i18n": "X-i18n TODO",
            description: "Description TODO"
          }
        },
        cardIds: {
          name: "cardIds",
          in: "query",
          type: "array",
          description: "card_ids"
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandOperatingAccountPatchPauseRequest" }
        }
      }
    },
    responses: { $ref: "MembershipAccountDefault", type: "object" }
  },
  v2BrandOperatingAccountPatchChangeCoach: {
    method: "patch",
    url: "/v2/brand/operating/accounts/patch_change_coach",
    operationId: "v2BrandOperatingAccountPatchChangeCoach",
    summary: "批量修改授课教练",
    tags: "V2#Brand#Operating#Account",
    parameters: {
      query: {
        type: {
          name: "type",
          in: "query",
          type: "string",
          description: "type",
          enum: ["prepay", "other"],
          "x-i18n": { prepay: "Prepay TODO", other: "Other TODO" },
          required: true
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "query"
        },
        productType: {
          name: "productType",
          in: "query",
          type: "string",
          description: "product_type",
          enum: ["elite_training", "group_training", "free_training"],
          "x-i18n": {
            elite_training: "Elite training TODO",
            group_training: "Group training TODO",
            free_training: "Free training TODO"
          }
        },
        cardType: {
          name: "cardType",
          in: "query",
          type: "string",
          description: "card_type",
          enum: ["type", "required", "enum", "x-i18n", "description"],
          "x-i18n": {
            type: "Type TODO",
            required: "Required TODO",
            enum: "Enum TODO",
            "x-i18n": "X-i18n TODO",
            description: "Description TODO"
          }
        },
        cardIds: {
          name: "cardIds",
          in: "query",
          type: "array",
          description: "card_ids"
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandOperatingAccountPatchChangeCoachRequest" }
        }
      }
    },
    responses: { $ref: "MembershipAccountDefault", type: "object" }
  },
  v2BrandOperatingPrepayPurchaseIndex: {
    method: "get",
    url: "/v2/brand/operating/prepay_purchases",
    operationId: "v2BrandOperatingPrepayPurchaseIndex",
    summary: "储值卡转购列表",
    tags: "V2#Brand#Operating#PrepayPurchase",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        recordType: {
          name: "recordType",
          in: "query",
          type: "string",
          description: "Record type TODO"
        },
        from: {
          name: "from",
          in: "query",
          type: "string",
          format: "date",
          description: "from"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          format: "date",
          description: "to"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "query"
        }
      }
    },
    responses: {
      $ref: "V2BrandOperatingPrepayPurchaseDefaultPagedArray",
      type: "object"
    }
  },
  v2BrandOperatingPrepayPurchaseSummary: {
    method: "get",
    url: "/v2/brand/operating/prepay_purchases/summary",
    operationId: "v2BrandOperatingPrepayPurchaseSummary",
    summary: "储值卡转购概览",
    tags: "V2#Brand#Operating#PrepayPurchase",
    parameters: {
      query: {
        recordType: { name: "recordType", in: "query", type: "string" },
        from: { name: "from", in: "query", type: "string", format: "date" },
        to: { name: "to", in: "query", type: "string", format: "date" },
        query: { name: "query", in: "query", type: "string" }
      }
    },
    responses: null
  },
  v2BrandOperatingReportSummary: {
    method: "get",
    url: "/v2/brand/operating/report/summary",
    operationId: "v2BrandOperatingReportSummary",
    summary: "总览",
    tags: "V2#Brand#Operating#Report",
    parameters: {
      query: {
        from: {
          name: "from",
          in: "query",
          type: "string",
          format: "date-time",
          required: true
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          format: "date-time",
          required: true
        },
        coachId: { name: "coachId", in: "query", type: "integer" },
        studioId: { name: "studioId", in: "query", type: "integer" },
        productType: { name: "productType", in: "query", type: "string" },
        productId: { name: "productId", in: "query", type: "integer" }
      }
    },
    responses: null
  },
  v2BrandOperatingReportTopTrainingMembers: {
    method: "get",
    url: "/v2/brand/operating/report/top_training_members",
    operationId: "v2BrandOperatingReportTopTrainingMembers",
    summary: "用户打卡榜",
    tags: "V2#Brand#Operating#Report",
    parameters: {
      query: {
        from: {
          name: "from",
          in: "query",
          type: "string",
          format: "date-time",
          required: true
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          format: "date-time",
          required: true
        },
        productType: {
          name: "productType",
          in: "query",
          type: "string",
          required: true
        },
        coachId: { name: "coachId", in: "query", type: "integer" },
        studioId: { name: "studioId", in: "query", type: "integer" },
        productId: { name: "productId", in: "query", type: "integer" }
      }
    },
    responses: null
  },
  v2BrandOrderCreate: {
    method: "post",
    url: "/v2/brand/orders/",
    operationId: "v2BrandOrderCreate",
    summary: "添加订单",
    tags: "V2#Brand#Order",
    parameters: {
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandOrderCreateRequest" }
        }
      }
    },
    responses: { $ref: "OrderDefault", type: "object" }
  },
  v2BrandPartnerConsumptionRecordIndex: {
    method: "get",
    url: "/v2/brand/partner_consumption_records/",
    operationId: "v2BrandPartnerConsumptionRecordIndex",
    summary: "合作商户消费记录列表",
    tags: "V2#Brand#PartnerConsumptionRecord",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        partnerId: {
          name: "partnerId",
          in: "query",
          type: "integer",
          description: "商户"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "query"
        },
        productIds: {
          name: "productIds",
          in: "query",
          type: "array",
          description: "product_ids"
        },
        from: {
          name: "from",
          in: "query",
          type: "string",
          format: "date",
          description: "from"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          format: "date",
          description: "to"
        }
      }
    },
    responses: {
      $ref: "PartnerConsumptionRecordDefaultPagedArray",
      type: "object"
    }
  },
  v2BrandPartnerConsumptionRecordShow: {
    method: "get",
    url: "/v2/brand/partner_consumption_records/{id}",
    operationId: "v2BrandPartnerConsumptionRecordShow",
    summary: "合作商户消费记录详情",
    tags: "V2#Brand#PartnerConsumptionRecord",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "Id TODO",
          required: true
        }
      }
    },
    responses: { $ref: "PartnerConsumptionRecordDefault", type: "object" }
  },
  v2BrandPartnerConsumptionRecordUpdate: {
    method: "put",
    url: "/v2/brand/partner_consumption_records/{id}",
    operationId: "v2BrandPartnerConsumptionRecordUpdate",
    summary: "编辑合作商户消费记录",
    tags: "V2#Brand#PartnerConsumptionRecord",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "Id TODO",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandPartnerConsumptionRecordUpdateRequest" }
        }
      }
    },
    responses: { $ref: "PartnerConsumptionRecordDefault", type: "object" }
  },
  v2BrandPartnerConsumptionRecordDestroy: {
    method: "delete",
    url: "/v2/brand/partner_consumption_records/{id}",
    operationId: "v2BrandPartnerConsumptionRecordDestroy",
    summary: "撤销合作商户消费记录",
    tags: "V2#Brand#PartnerConsumptionRecord",
    parameters: {
      path: { id: { name: "id", in: "path", type: "integer", required: true } }
    },
    responses: null
  },
  v2BrandPartnerConsumptionRecordSummary: {
    method: "get",
    url: "/v2/brand/partner_consumption_records/summary",
    operationId: "v2BrandPartnerConsumptionRecordSummary",
    summary: "合作商户消费记录统计",
    tags: "V2#Brand#PartnerConsumptionRecord",
    parameters: {
      query: {
        partnerId: { name: "partnerId", in: "query", type: "integer" },
        query: { name: "query", in: "query", type: "string" },
        productIds: { name: "productIds", in: "query", type: "array" },
        from: { name: "from", in: "query", type: "string", format: "date" },
        to: { name: "to", in: "query", type: "string", format: "date" }
      }
    },
    responses: null
  },
  v2BrandPartnerConsumptionRecordExport: {
    method: "get",
    url: "/v2/brand/partner_consumption_records/export",
    operationId: "v2BrandPartnerConsumptionRecordExport",
    summary: "合作商户消费记录导出",
    tags: "V2#Brand#PartnerConsumptionRecord",
    parameters: {
      query: {
        partnerId: { name: "partnerId", in: "query", type: "integer" },
        query: { name: "query", in: "query", type: "string" },
        productIds: { name: "productIds", in: "query", type: "array" },
        from: { name: "from", in: "query", type: "string", format: "date" },
        to: { name: "to", in: "query", type: "string", format: "date" }
      }
    },
    responses: { type: "file" }
  },
  v2BrandPartnerConsumptionRecordConsume: {
    method: "post",
    url: "/v2/brand/partner_consumption_records/consume",
    operationId: "v2BrandPartnerConsumptionRecordConsume",
    summary: "合作商户消费录入",
    tags: "V2#Brand#PartnerConsumptionRecord",
    parameters: {
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandPartnerConsumptionRecordConsumeRequest" }
        }
      }
    },
    responses: { $ref: "PartnerConsumptionRecordDefault", type: "object" }
  },
  v2BrandPartnerProductOptions: {
    method: "get",
    url: "/v2/brand/partner_products/options",
    operationId: "v2BrandPartnerProductOptions",
    summary: "合作商户商品选项列表",
    tags: "V2#Brand#PartnerProduct",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        partnerId: {
          name: "partnerId",
          in: "query",
          type: "integer",
          description: "商户"
        },
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "激活"
        },
        ids: { name: "ids", in: "query", type: "array", description: "ids" }
      }
    },
    responses: { $ref: "PartnerProductOptionPagedArray", type: "object" }
  },
  v2BrandPartnerProductIndex: {
    method: "get",
    url: "/v2/brand/partner_products/",
    operationId: "v2BrandPartnerProductIndex",
    summary: "合作商户商品列表",
    tags: "V2#Brand#PartnerProduct",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        partnerId: {
          name: "partnerId",
          in: "query",
          type: "integer",
          description: "商户"
        },
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "激活"
        }
      }
    },
    responses: { $ref: "PartnerProductDefaultPagedArray", type: "object" }
  },
  v2BrandPartnerProductCreate: {
    method: "post",
    url: "/v2/brand/partner_products/",
    operationId: "v2BrandPartnerProductCreate",
    summary: "新建合作商户商品",
    tags: "V2#Brand#PartnerProduct",
    parameters: {
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandPartnerProductCreateRequest" }
        }
      }
    },
    responses: { $ref: "PartnerProductDefault", type: "object" }
  },
  v2BrandPartnerProductShow: {
    method: "get",
    url: "/v2/brand/partner_products/{id}",
    operationId: "v2BrandPartnerProductShow",
    summary: "合作商户商品详情",
    tags: "V2#Brand#PartnerProduct",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "ID",
          required: true
        }
      }
    },
    responses: { $ref: "PartnerProductDefault", type: "object" }
  },
  v2BrandPartnerProductUpdate: {
    method: "put",
    url: "/v2/brand/partner_products/{id}",
    operationId: "v2BrandPartnerProductUpdate",
    summary: "编辑合作商户商品",
    tags: "V2#Brand#PartnerProduct",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandPartnerProductUpdateRequest" }
        }
      }
    },
    responses: { $ref: "PartnerProductDefault", type: "object" }
  },
  v2BrandPartnerProductDestroy: {
    method: "delete",
    url: "/v2/brand/partner_products/{id}",
    operationId: "v2BrandPartnerProductDestroy",
    summary: "删除合作商户商品",
    tags: "V2#Brand#PartnerProduct",
    parameters: {
      path: { id: { name: "id", in: "path", type: "integer", required: true } }
    },
    responses: null
  },
  v2BrandPartnerOptions: {
    method: "get",
    url: "/v2/brand/partners/options",
    operationId: "v2BrandPartnerOptions",
    summary: "合作商户选项列表",
    tags: "V2#Brand#Partner",
    parameters: {
      query: {
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "激活"
        },
        ids: { name: "ids", in: "query", type: "array", description: "ids" }
      }
    },
    responses: { $ref: "PartnerOption", type: "array" }
  },
  v2BrandPartnerIndex: {
    method: "get",
    url: "/v2/brand/partners/",
    operationId: "v2BrandPartnerIndex",
    summary: "合作商户列表",
    tags: "V2#Brand#Partner",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        active: {
          name: "active",
          in: "query",
          type: "boolean",
          description: "激活"
        }
      }
    },
    responses: { $ref: "PartnerDefaultPagedArray", type: "object" }
  },
  v2BrandPartnerCreate: {
    method: "post",
    url: "/v2/brand/partners/",
    operationId: "v2BrandPartnerCreate",
    summary: "新建合作商户",
    tags: "V2#Brand#Partner",
    parameters: {
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandPartnerCreateRequest" }
        }
      }
    },
    responses: { $ref: "PartnerDefault", type: "object" }
  },
  v2BrandPartnerShow: {
    method: "get",
    url: "/v2/brand/partners/{id}",
    operationId: "v2BrandPartnerShow",
    summary: "合作商户详情",
    tags: "V2#Brand#Partner",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "ID",
          required: true
        }
      }
    },
    responses: { $ref: "PartnerDefault", type: "object" }
  },
  v2BrandPartnerUpdate: {
    method: "put",
    url: "/v2/brand/partners/{id}",
    operationId: "v2BrandPartnerUpdate",
    summary: "编辑合作商户",
    tags: "V2#Brand#Partner",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandPartnerUpdateRequest" }
        }
      }
    },
    responses: { $ref: "PartnerDefault", type: "object" }
  },
  v2BrandPartnerDestroy: {
    method: "delete",
    url: "/v2/brand/partners/{id}",
    operationId: "v2BrandPartnerDestroy",
    summary: "删除合作商户",
    tags: "V2#Brand#Partner",
    parameters: {
      path: { id: { name: "id", in: "path", type: "integer", required: true } }
    },
    responses: null
  },
  v2BrandReportFinanceCoachCoursesList: {
    method: "get",
    url: "/v2/brand/report/finances/coach_courses_list",
    operationId: "v2BrandReportFinanceCoachCoursesList",
    summary: "教练上课记录明细",
    tags: "V2#Brand#Report#Finance",
    parameters: {
      query: {
        coachId: {
          name: "coachId",
          in: "query",
          type: "integer",
          description: "coach_id",
          required: true
        },
        studioId: {
          name: "studioId",
          in: "query",
          type: "integer",
          description: "studio_id"
        },
        from: {
          name: "from",
          in: "query",
          type: "string",
          format: "date-time",
          description: "from"
        },
        to: {
          name: "to",
          in: "query",
          type: "string",
          format: "date-time",
          description: "to"
        },
        courseType: {
          name: "courseType",
          in: "query",
          type: "string",
          description: "course_type"
        }
      }
    },
    responses: { $ref: "V2BrandFinanceCoachCourseDefault", type: "object" }
  },
  v2BrandReportFinanceExpirations: {
    method: "get",
    url: "/v2/brand/report/finances/expirations",
    operationId: "v2BrandReportFinanceExpirations",
    summary: "到期会员卡",
    tags: "V2#Brand#Report#Finance",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        accountType: {
          name: "accountType",
          in: "query",
          type: "string",
          description: "account_type",
          enum: ["prepay", "entry"],
          "x-i18n": { prepay: "account_type", entry: "account_type" },
          required: true
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "根据姓名或手机号搜索"
        },
        duration: {
          name: "duration",
          in: "query",
          type: "array",
          description: "duration"
        }
      }
    },
    responses: {
      $ref: "V2BrandFinanceMembershipRecordDefaultPagedArray",
      type: "object"
    }
  },
  v2BrandReportFinanceExpiredMemberCoupons: {
    method: "get",
    url: "/v2/brand/report/finances/expired_member_coupons",
    operationId: "v2BrandReportFinanceExpiredMemberCoupons",
    summary: "到期代金券",
    tags: "V2#Brand#Report#Finance",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "根据姓名或手机号搜索"
        },
        duration: {
          name: "duration",
          in: "query",
          type: "array",
          description: "duration"
        }
      }
    },
    responses: {
      $ref: "V2BrandCouponMemberCouponDefaultPagedArray",
      type: "object"
    }
  },
  v2BrandReportFinanceExpirationsSummary: {
    method: "get",
    url: "/v2/brand/report/finances/expirations_summary",
    operationId: "v2BrandReportFinanceExpirationsSummary",
    summary: "到期会员卡统计",
    tags: "V2#Brand#Report#Finance",
    parameters: {
      query: {
        accountType: {
          name: "accountType",
          in: "query",
          type: "string",
          description: "account_type",
          enum: ["prepay", "entry"],
          "x-i18n": { prepay: "account_type", entry: "account_type" },
          required: true
        }
      }
    },
    responses: {
      $ref: "V2BrandFinanceMembershipRecordSummary",
      type: "object"
    }
  },
  v2BrandReportFinanceExpiredMemberCouponsSummary: {
    method: "get",
    url: "/v2/brand/report/finances/expired_member_coupons_summary",
    operationId: "v2BrandReportFinanceExpiredMemberCouponsSummary",
    summary: "到期代金券统计",
    tags: "V2#Brand#Report#Finance",
    parameters: null,
    responses: { $ref: "V2BrandCouponMemberCouponSummary", type: "object" }
  },
  v2BrandReportFinanceExportExpirations: {
    method: "get",
    url: "/v2/brand/report/finances/export_expirations",
    operationId: "v2BrandReportFinanceExportExpirations",
    summary: "导出到期会员卡",
    tags: "V2#Brand#Report#Finance",
    parameters: {
      query: {
        accountType: {
          name: "accountType",
          in: "query",
          type: "string",
          required: true
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "根据姓名或手机号搜索"
        },
        duration: { name: "duration", in: "query", type: "array" }
      }
    },
    responses: { type: "file" }
  },
  v2BrandReportFinanceExportExpiredMemberCoupons: {
    method: "get",
    url: "/v2/brand/report/finances/export_expired_member_coupons",
    operationId: "v2BrandReportFinanceExportExpiredMemberCoupons",
    summary: "导出到期代金券",
    tags: "V2#Brand#Report#Finance",
    parameters: {
      query: {
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "根据姓名或手机号搜索"
        },
        duration: { name: "duration", in: "query", type: "array" }
      }
    },
    responses: { type: "file" }
  },
  v2BrandReportFinanceCancelExpiration: {
    method: "patch",
    url: "/v2/brand/report/finances/cancel_expiration/{recordId}",
    operationId: "v2BrandReportFinanceCancelExpiration",
    summary: "恢复过期记录",
    tags: "V2#Brand#Report#Finance",
    parameters: {
      path: {
        recordId: {
          name: "recordId",
          in: "path",
          type: "integer",
          description: "record_id",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandReportFinanceCancelExpirationRequest" }
        }
      }
    },
    responses: null
  },
  v2BrandReportFinancePartners: {
    method: "get",
    url: "/v2/brand/report/finances/partners",
    operationId: "v2BrandReportFinancePartners",
    summary: "合作商户消费",
    tags: "V2#Brand#Report#Finance",
    parameters: {
      query: {
        partnerId: { name: "partnerId", in: "query", type: "integer" },
        query: { name: "query", in: "query", type: "string" },
        productIds: { name: "productIds", in: "query", type: "array" },
        from: { name: "from", in: "query", type: "string", format: "date" },
        to: { name: "to", in: "query", type: "string", format: "date" }
      }
    },
    responses: null
  },
  v2BrandReportFinancePartnersSummary: {
    method: "get",
    url: "/v2/brand/report/finances/partners_summary",
    operationId: "v2BrandReportFinancePartnersSummary",
    summary: "合作商户消费统计",
    tags: "V2#Brand#Report#Finance",
    parameters: {
      query: {
        partnerId: { name: "partnerId", in: "query", type: "integer" },
        query: { name: "query", in: "query", type: "string" },
        productIds: { name: "productIds", in: "query", type: "array" },
        from: { name: "from", in: "query", type: "string", format: "date" },
        to: { name: "to", in: "query", type: "string", format: "date" }
      }
    },
    responses: null
  },
  v2BrandReportFinanceExportPartners: {
    method: "get",
    url: "/v2/brand/report/finances/export_partners",
    operationId: "v2BrandReportFinanceExportPartners",
    summary: "合作商户消费导出",
    tags: "V2#Brand#Report#Finance",
    parameters: {
      query: {
        partnerId: { name: "partnerId", in: "query", type: "integer" },
        query: { name: "query", in: "query", type: "string" },
        productIds: { name: "productIds", in: "query", type: "array" },
        from: { name: "from", in: "query", type: "string", format: "date" },
        to: { name: "to", in: "query", type: "string", format: "date" }
      }
    },
    responses: { type: "file" }
  },
  v2BrandStudioCoachOptions: {
    method: "get",
    url: "/v2/brand/studio/studios/{studioId}/coaches/options",
    operationId: "v2BrandStudioCoachOptions",
    summary: "门店教练选项列表",
    tags: "V2#Brand#Studio#Coach",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        ids: { name: "ids", in: "query", type: "array", description: "ids" }
      },
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "Studio TODO",
          required: true
        }
      }
    },
    responses: { $ref: "V2StudioCoachOptionPagedArray", type: "object" }
  },
  v2BrandStudioCoachIndex: {
    method: "get",
    url: "/v2/brand/studio/studios/{studioId}/coaches/",
    operationId: "v2BrandStudioCoachIndex",
    summary: "门店教练列表",
    tags: "V2#Brand#Studio#Coach",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      },
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "Studio TODO",
          required: true
        }
      }
    },
    responses: { $ref: "V2StudioCoachDefaultPagedArray", type: "object" }
  },
  v2BrandStudioCoachCreate: {
    method: "post",
    url: "/v2/brand/studio/studios/{studioId}/coaches/",
    operationId: "v2BrandStudioCoachCreate",
    summary: "新建门店教练",
    tags: "V2#Brand#Studio#Coach",
    parameters: {
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "Studio TODO",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandStudioCoachCreateRequest" }
        }
      }
    },
    responses: { $ref: "V2StudioCoachDefault", type: "object" }
  },
  v2BrandStudioCoachShow: {
    method: "get",
    url: "/v2/brand/studio/coaches/{id}",
    operationId: "v2BrandStudioCoachShow",
    summary: "门店教练详情",
    tags: "V2#Brand#Studio#Coach",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "ID",
          required: true
        }
      }
    },
    responses: { $ref: "V2StudioCoachDefault", type: "object" }
  },
  v2BrandStudioCoachUpdate: {
    method: "put",
    url: "/v2/brand/studio/coaches/{id}",
    operationId: "v2BrandStudioCoachUpdate",
    summary: "编辑门店教练",
    tags: "V2#Brand#Studio#Coach",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "ID",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandStudioCoachUpdateRequest" }
        }
      }
    },
    responses: { $ref: "V2StudioCoachDefault", type: "object" }
  },
  v2BrandStudioCoachDestroy: {
    method: "delete",
    url: "/v2/brand/studio/coaches/{id}",
    operationId: "v2BrandStudioCoachDestroy",
    summary: "删除门店教练",
    tags: "V2#Brand#Studio#Coach",
    parameters: {
      path: { id: { name: "id", in: "path", type: "integer", required: true } }
    },
    responses: null
  },
  v2BrandStudioProductOptions: {
    method: "get",
    url: "/v2/brand/studio/studios/{studioId}/products/options",
    operationId: "v2BrandStudioProductOptions",
    summary: "门店产品选项列表",
    tags: "V2#Brand#Studio#Product",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        ids: { name: "ids", in: "query", type: "array", description: "ids" }
      },
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "Studio TODO",
          required: true
        }
      }
    },
    responses: { $ref: "V2StudioProductOptionPagedArray", type: "object" }
  },
  v2BrandStudioProductIndex: {
    method: "get",
    url: "/v2/brand/studio/studios/{studioId}/products/",
    operationId: "v2BrandStudioProductIndex",
    summary: "门店产品列表",
    tags: "V2#Brand#Studio#Product",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        }
      },
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "Studio TODO",
          required: true
        }
      }
    },
    responses: { $ref: "V2StudioProductDefaultPagedArray", type: "object" }
  },
  v2BrandStudioProductCreate: {
    method: "post",
    url: "/v2/brand/studio/studios/{studioId}/products/",
    operationId: "v2BrandStudioProductCreate",
    summary: "新建门店产品",
    tags: "V2#Brand#Studio#Product",
    parameters: {
      path: {
        studioId: {
          name: "studioId",
          in: "path",
          type: "integer",
          description: "Studio TODO",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandStudioProductCreateRequest" }
        }
      }
    },
    responses: { $ref: "V2StudioProductDefault", type: "object" }
  },
  v2BrandStudioProductShow: {
    method: "get",
    url: "/v2/brand/studio/products/{id}",
    operationId: "v2BrandStudioProductShow",
    summary: "门店产品详情",
    tags: "V2#Brand#Studio#Product",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "id",
          required: true
        }
      }
    },
    responses: { $ref: "V2StudioProductDefault", type: "object" }
  },
  v2BrandStudioProductUpdate: {
    method: "put",
    url: "/v2/brand/studio/products/{id}",
    operationId: "v2BrandStudioProductUpdate",
    summary: "编辑门店产品",
    tags: "V2#Brand#Studio#Product",
    parameters: {
      path: {
        id: {
          name: "id",
          in: "path",
          type: "integer",
          description: "id",
          required: true
        }
      },
      body: {
        json: {
          name: "json",
          in: "body",
          schema: { $ref: "V2BrandStudioProductUpdateRequest" }
        }
      }
    },
    responses: { $ref: "V2StudioProductDefault", type: "object" }
  },
  v2BrandStudioProductDestroy: {
    method: "delete",
    url: "/v2/brand/studio/products/{id}",
    operationId: "v2BrandStudioProductDestroy",
    summary: "删除门店产品",
    tags: "V2#Brand#Studio#Product",
    parameters: {
      path: { id: { name: "id", in: "path", type: "integer", required: true } }
    },
    responses: null
  },
  v2BrandStudioAccessLogIndex: {
    method: "get",
    url: "/v2/brand/studio_access_logs/",
    operationId: "v2BrandStudioAccessLogIndex",
    summary: "门店入场记录列表",
    tags: "V2#Brand#StudioAccessLog",
    parameters: {
      query: {
        page: {
          name: "page",
          in: "query",
          type: "integer",
          required: false,
          description: "当前页码"
        },
        pageSize: {
          name: "pageSize",
          in: "query",
          type: "integer",
          required: false,
          description: "单页记录数"
        },
        studioId: {
          name: "studioId",
          in: "query",
          type: "integer",
          description: "studio_id"
        },
        query: {
          name: "query",
          in: "query",
          type: "string",
          description: "query"
        }
      }
    },
    responses: { $ref: "V2StudioAccessLogDefaultPagedArray", type: "object" }
  }
};
const idMaps = {};
