<script>

import { getTableColumns, getTableFilterSchema, getAPI } from './helper.js'
import SpStatus from './SpStatus'
import SpForm from './SpForm'
import SpAvatar from './SpAvatar'
import SpImg from './SpImg'
import { get, isEqual, debounce } from 'lodash'
import { eventEmitter } from './eventEmitter.js'

export default {
  name: 'SpTable',
  props: {
    operationId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null
    },
    showIndex: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => {},
    },
    formConfig: {
      type: Object,
      default: () => {},
    },
    formProps: {
      type: Object,
      default: () => {},
    },
    initialValues: {
      type: Object,
      default: () => {},
    },
    params: {
      type: Array,
    },
    customColumns: {
      type: Array,
    },
    withoutCard: {
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    tips: {
      type: String
    },
    hasFilters: {
      type: Boolean,
      default: true
    },
    pageSize: {
      type: Number,
      default: 20
    }
  },

  data() {
    const { operationId, config, formConfig } = this
    const columns = getTableColumns(operationId, config)
    const formSchema = getTableFilterSchema(operationId, {...formConfig, apiVersion: config.apiVersion})

    return {
      sourceData: null,
      columns,
      formSchema,
      isLoading: false,
      pagination: null,
      filter: this.initialValues,
      pageParams: {
        page: 1,
        pageSize: this.pageSize,
      },
    }
  },

  computed: {
    actualColumns() {
      if (!this.columns) {
        return null
      } else {
        
        let result = this.columns.map((item) => {
          if (item.type === 'boolean') {
            item.width = '100'
          }

          return item
        })

        if (this.showIndex) {
          const indexColumn = {
            title: '序号',
            width: 80,
            render: (cell, row, index) => {
              if (this.pagination) {
                return this.pagination.pageSize * (this.pagination.currentPage-1) + index + 1
              } else {
                return index+1;
              }
            }
          }
          return [indexColumn, ...result]
        } else {
          return result
        }
      }
    },
  },

  mounted() {
    // debounce fetch data, prevent duplicated api request on multiple filter changed at same time.
    this.fetchData = debounce(function () {
      const { operationId, params = [], config } = this
      const { apiVersion = 'v1' } = config
      this.isLoading = true
      getAPI(apiVersion)[operationId](...params, { ...this.pageParams, ...this.filter }).then(
        (res) => {
          if (res.pagination) {
            this.sourceData = res.data
            this.pagination = res.pagination
          } else {
            this.sourceData = res
          }

          this.isLoading = false
        }
      )
    }, 100)

    this.fetchData()

    eventEmitter.on(this.operationId, () => {
      this.fetchData()
    })
  },

  beforeDestroy() {
    eventEmitter.remove(this.operationId)
  },

  watch: {
    pageParams: {
      handler: function () {
        this.fetchData()
      },
      deep: true,
    },

    params: {
      handler: function (v, v1) {
        if (!isEqual(v, v1)) {
          this.pageParams =  {
            page: 1,
            pageSize: this.pageSize,
          }
          this.fetchData()
        }
      },
      deep: true,
    },

    filter: {
      handler: function () {
        this.pageParams =  {
          page: 1,
          pageSize: this.pageSize,
        },
        this.fetchData()
      },
      deep: true,
    },

    initialValues: {
      handler: function () {
        this.filter = this.initialValues
      },
      deep: true,
    }
  },

  methods: {
    reload() {
      this.fetchData()
    },

    renderByType(cellValue, row, item) {
      const typeMaps = {
        boolean: (cellValue, row, item) => {
          return (
            <div class="center">
              {cellValue ? <SpStatus type="green" /> : <SpStatus type="red" />}
            </div>
          )
        },
        MemberMemberDefault: (cellValue, row, item) => (
          <SpAvatar data={cellValue} />
        ),
        MemberMemberEssential: (cellValue, row, item) => (
          <SpAvatar data={cellValue} />
        ),
        AttachmentDefault: (cellValue, row, item) => (
          <SpImg src={cellValue?.url} />
        ),
      }
      const renderFunc = typeMaps[item.type]

      if (renderFunc) {
        return renderFunc(cellValue, row, item)
      } else {
        if (item.type !== item.type.toLowerCase()) {
          return (
            <div style={{ color: '#f74e57' }}>
              {item.type} 需要自定义展示方式
            </div>
          )
        }

        if (item['x-i18n']) {
          return get(item['x-i18n'], cellValue) || cellValue
        }

        return cellValue
      }
    },

    handleChangePage(page) {
      this.pageParams.page = page
    },
  },

  render() {
    return (
      <div class={`sp-table ${this.withoutCard ? 'without-card' : ''}`}>
        <ElCard shadow={this.withoutCard ? 'never' : 'always'}>
          {
            (!this.withoutCard && this.title) && <h1 class="title">{this.title}</h1>
          }
          {
            this.hasFilters && (
              this.$scopedSlots.filters ? 
              <div class="row-operation">
                {this.$scopedSlots.filters(this.filter)}
              </div>
              : 
              <SpForm
                customSchema={this.formSchema}
                showSubmitBtn={false}
                initialValues={this.initialValues}
                formProps={{
                  inline: true,
                  ...this.formProps,
                }}
                onValueChange={(v) => {
                  this.filter = Object.assign({}, this.filter, v)
                }}
              />
            )
          }
          
          { this.tips && (
            <el-tooltip class="table-tips" effect="light" placement="top">
              <div slot="content">{this.tips}</div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          )}
          

          <el-table
            data={this.sourceData}
            style="width: 100%"
            v-loading={this.isLoading}
          >
            {this.actualColumns &&
              this.actualColumns.map((item, i) => (
                <el-table-column
                  key={item.prop}
                  prop={item.prop}
                  label={item.title}
                  width={item.width}
                  formatter={(row, column, cellValue, index) => {
                    if (item.render) {
                      return item.render(cellValue, row, index)
                    } else {
                      return this.renderByType(cellValue, row, item, index)
                    }
                  }}
                >
                {
                    item.tips && 
                    <template slot="header" slot-scope="scope">
                      {item.title}
                      <el-tooltip class="table-column-tips" effect="light" placement="top">
                        <div slot="content">{item.tips}</div>
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                    </template>
                  }
                </el-table-column>
              ))}
            {this.$scopedSlots.extra && (
              <el-table-column
                key="extra"
                label="操作"
                formatter={(row, column, cellValue) => {
                  return (
                    <div class="row-operation">
                      {this.$scopedSlots.extra(row)}
                    </div>
                  )
                }}
              ></el-table-column>
            )}
          </el-table>
          {this.showPagination && this.pagination && (
            <div class="sp-footer">
              <el-pagination
                class="sp-table-pagination"
                background
                layout="total, ->, prev, pager, next, jumper"
                total={this.pagination.totalCount}
                current-page={this.pagination.currentPage}
                page-size={this.pagination.pageSize}
                on-current-change={this.handleChangePage}
              />
            </div>
          )}
        </ElCard>
      </div>
    )
  },
}
</script>

<style lang='scss'>
.sp-table {
  padding: 10px 0;

  .title {
    margin: -20px -20px 20px -20px;
    padding: 16px 24px;
    font-size: 16px;
    font-weight: bold;
    color: #18191a;
    border-bottom: 1px solid rgba(#000, 0.1);
    min-height: 51px;
    overflow: hidden;
  }

  &.without-card {
    .el-card {
      border: 0;
    }

    .el-card__body {
      padding: 0;
    }
  }

  .action-item {
    color: $blue;
    cursor: pointer;
  }

  .table-tips {
    float: right;
    margin-top: -1.5em;
  }

  .table-column-tips {
    margin-left: 0.5em;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .space-around {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .row-operation {
    display: flex;
    flex-wrap: wrap;
    color: $blue;
    margin-right: -0.5em;

    & > * {
      cursor: pointer;
      margin-bottom: 0.5em;
      margin-right: 0.5em;
    }

    br {
      flex-basis: 100%;
      width: 0px;
      height: 0px;
      margin: 0.5em 0;
      overflow: hidden;
    }

    .danger {
      color: $red;
    }
  }

  .sp-table-pagination {
    padding: 0;
    margin-top: 24px;
  }
}
</style>
