<script>
import moment from 'moment'
import {
  getFormSchema,
  getAPI,
  getIndexByOperationId,
  getShowByOperationId,
  isUpdateOperationId,
} from './helper'
import ProductSelect from './select/ProductSelect.vue'
import StudioSelect from './select/StudioSelect.vue'
import CouponSelect from './select/CouponSelect.vue'
import TagSelect from './select/TagSelect.vue'
import CoachSelect from './select/CoachSelect.vue'
import CoachLevelSelect from './select/CoachLevelSelect.vue'
import ZoneSelect from './select/ZoneSelect.vue'
import { has, identity, isFunction, isEmpty, isObject, omit } from 'lodash'
import SpSearch from './SpSearch.vue'
import SpButtonGroup from './SpButtonGroup.vue'
import { eventEmitter } from './eventEmitter'
import SpUpload from './SpUpload.vue'
import MemberSelect from './select/MemberSelect.vue'

export default {
  name: 'SpForm',
  props: {
    operationId: {
      type: String,
    },
    reloadOperationId: {
      type: String,
    },
    fetchOperationId: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: () => {},
    },
    customSchema: {
      type: Array,
    },
    formatResult: {
      type: Function,
    },
    beforeSubmit: {
      type: Function,
    },
    afterSubmit: {
      type: Function,
    },
    reqeust: {
      type: Function,
    },
    submit: {
      type: Function,
    },
    params: {
      type: Array,
    },
    showSubmitBtn: {
      type: Boolean,
      default: true,
    },
    customRules: {
      type: Array,
    },
    initialValues: {
      type: Object,
      default: () => {},
    },
    tips: {
      type: String,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    const _this = this
    const { operationId, customRules, customSchema } = this
    const schema = customSchema || getFormSchema(operationId, this.config)
    console.log(
      'form: ',
      schema.map((v) => ({ ...v }))
    )
    const form = schema.reduce((accu, curr) => {
      let defaultValue = null

      if (has(_this.initialValues, curr.prop)) {
        defaultValue = _this.initialValues[curr.prop]
      } else {
        if (curr.type === 'boolean') {
          defaultValue = false
        } else if (curr.type === 'array') {
          if (curr.prop === 'availableTimeRange') {
            defaultValue = null
          } else {
            defaultValue = []
          }
        }
      }

      return Object.assign(accu, { [curr.prop]: defaultValue })
    }, {})
    const rules = customRules
      ? customRules
      : schema.reduce((accu, curr) => {
          const item = [
            {
              required: true,
              message: `请输入${
                typeof curr.title === 'function' ? curr.title(form) : curr.title
              }`,
              trigger: 'blur',
            },
          ]
          if (curr.required) {
            return Object.assign(accu, { [curr.prop]: item })
          } else {
            return accu
          }
        }, {})

    return {
      form,
      schema,
      rules,
      isLoading: false,
      submitLoading: false,
      errors: {},
    }
  },

  computed: {
    formProps() {
      const dp = this.$attrs?.formProps || {}
      const result = {
        ...omit(this.$attrs || {}, 'formProps'),
        ...dp,
      }

      return result
    },
  },

  mounted() {
    const { operationId } = this
    if (
      this.fetchOperationId ||
      (operationId && isUpdateOperationId(operationId))
    ) {
      this.fetchData()
    }
  },

  watch: {
    form: {
      handler: function (curr) {
        this.$emit('valueChange', curr)
      },
      deep: true,
    },

    initialValues: {
      handler: function (curr) {
        this.reset()
      },
      deep: true,
    },

    params(curr) {
      if (this.fetchOperationId || isUpdateOperationId(this.operationId)) {
        this.fetchData()
      }
    },
  },

  methods: {
    fetchData() {
      const { operationId, params = [], formatResult = identity, config } = this
      const { apiVersion = 'v1' } = config
      const showId = this.fetchOperationId || getShowByOperationId(operationId)
      const fetchApi = getAPI(apiVersion)[showId]
      this.isLoading = true

      if (fetchApi.length != 0 && params == 0) {
        return
      }

      if (!fetchApi) {
        throw Error(`没有找到 ${showId} 对应的请求。`)
      }
      fetchApi(...params)
        .then((res) => {
          const defaultFormat = (obj) => {
            if (obj.availableTimeRange && isEmpty(obj.availableTimeRange)) {
              obj.availableTimeRange = null
            }
            Object.entries(obj).forEach(([k, v]) => {
              if (isObject(v)) {
                obj[`${k}Id`] = v.id
              }
            })

            return obj
          }

          this.form = Object.assign(
            {},
            formatResult(defaultFormat(res))
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    async handleSubmit() {
      if (this.readOnly) {
        return
      }
      const {
        operationId,
        params = [],
        beforeSubmit = identity,
        afterSubmit = identity,
        config,
      } = this
      const { apiVersion = 'v1' } = config
      const fetchApi = getAPI(apiVersion)[operationId]
      const actualForm = await beforeSubmit(this.form)
      this.submitLoading = true

      if (!fetchApi) {
        throw Error(`没有找到 ${operationId} 对应的请求。`)
      }

      return new Promise((resolve, reject) => {
        this.$refs.spForm.validate((valid) => {
          if (valid) {
            fetchApi(...params, actualForm)
              .then((res) => {
                this.$emit('success')
                this.submitLoading = false
                eventEmitter.emit(getIndexByOperationId(this.operationId))
                if (this.reloadOperationId) {
                  eventEmitter.emit(this.reloadOperationId)
                }
                afterSubmit()
                resolve(res)
              })
              .catch((err) => {
                this.submitLoading = false
                console.log(this.errors, err.response.data.errors)
                this.errors = err.response.data.errors
                reject(err)
              })
          } else {
            this.submitLoading = false
            reject()
          }
        })
      })
    },

    generateSelect(item) {
      const i18n = item.type === 'array' ? item.items['x-i18n'] : item['x-i18n']
      const options = Object.entries(i18n).map(([k, v]) => ({
        label: v,
        value: k,
      }))

      return (
        <el-select
          multiple={item.type === 'array'}
          value={this.form[item.prop]}
          on-change={(v) => (this.form[item.prop] = v)}
          placeholder={`请选择${item.desc}`}
          allow-create={!!item.allowCustom}
          filterable={!!item.allowCustom}
          labelWidth="300"
        >
          {options.map((item) => (
            <el-option
              key={item.value}
              label={item.label}
              value={item.value}
            ></el-option>
          ))}
        </el-select>
      )
    },

    getValue() {
      return this.form
    },

    reset() {
      const { schema } = this

      Object.entries(this.form).forEach(([key, val]) => {
        let defaultValue = null
        let curr = schema.find((item) => item.prop === key)

        if (!curr) {
          return
        }

        if (has(this.initialValues, key)) {
          defaultValue = this.initialValues[key]
          console.log(defaultValue)
        } else {
          if (curr.type === 'boolean') {
            defaultValue = false
          } else if (curr.type === 'array') {
            if (key === 'availableTimeRange') {
              defaultValue = null
            } else {
              defaultValue = []
            }
          }
        }

        this.form[key] = defaultValue
      })
    },

    renderFormItem(item) {
      const tablePropMaps = {
        query: (curr) => (
          <SpSearch
            value={this.form[curr.prop]}
            on-change={(v) => (this.form[curr.prop] = v)}
            placeholder={curr.desc}
          />
        ),
      }
      const propMaps = {
        studioIds: (curr) => (
          <StudioSelect
            value={this.form[curr.prop]}
            on-change={(v) => (this.form[curr.prop] = v)}
            multiple={curr.type === 'array'}
          />
        ),
        couponId: (curr) => (
          <CouponSelect
            value={this.form[curr.prop]}
            on-change={(v) => (this.form[curr.prop] = v)}
            multiple={curr.type === 'array'}
          />
        ),
        // tagList: (curr) => (
        //   <TagSelect
        //     value={this.form[curr.prop]}
        //     on-change={(v) => (this.form[curr.prop] = v)}
        //     multiple={curr.type === 'array'}
        //   />
        // ),
        memberId: (curr) => (
          <MemberSelect
            value={this.form[curr.prop]}
            on-change={(v) => (this.form[curr.prop] = v)}
          />
        ),
        productIds: (curr) => (
          <ProductSelect
            value={this.form[curr.prop]}
            on-change={(v) => (this.form[curr.prop] = v)}
            multiple={curr.type === 'array'}
            product-type={this.form.productType || ''}
          />
        ),
        productId: (curr) => (
          <ProductSelect
            value={this.form[curr.prop]}
            on-change={(v) => (this.form[curr.prop] = v)}
            multiple={curr.type === 'array'}
            product-type={this.form.productType || ''}
          />
        ),
        coachId: (curr) => (
          <CoachSelect
            value={this.form[curr.prop]}
            on-change={(v) => (this.form[curr.prop] = v)}
            multiple={curr.type === 'array'}
          />
        ),
        coachLevelId: (curr) => (
          <CoachLevelSelect
            value={this.form[curr.prop]}
            on-change={(v) => (this.form[curr.prop] = v)}
            multiple={curr.type === 'array'}
          />
        ),
        coachLevelIds: (curr) => (
          <CoachLevelSelect
            value={this.form[curr.prop]}
            on-change={(v) => (this.form[curr.prop] = v)}
            multiple={curr.type === 'array'}
          />
        ),
        primaryCoachId: (curr) => (
          <CoachSelect
            value={this.form[curr.prop]}
            on-change={(v) => (this.form[curr.prop] = v)}
            multiple={curr.type === 'array'}
          />
        ),
        studioId: (curr) => (
          <StudioSelect
            value={this.form[curr.prop]}
            on-change={(v) => (this.form[curr.prop] = v)}
            multiple={curr.type === 'array'}
          />
        ),
        zoneId: (curr) => (
          <ZoneSelect
            studioId={this.form['studioId']}
            value={this.form[curr.prop]}
            on-change={(v) => (this.form[curr.prop] = v)}
            multiple={curr.type === 'array'}
          />
        ),
        availableTimeRange: (curr) => (
          <el-date-picker
            value={this.form[curr.prop]}
            on-input={(v) => {
              this.form[curr.prop] = v.map((date) =>
                moment(date).format('YYYY/MM/DD HH:mm:ss')
              )
            }}
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        ),
      }
      const typeMaps = {
        boolean: (curr) => {
          if (this.customSchema) {
            return (
              <SpButtonGroup
                value={this.form[curr.prop]}
                on-change={(v) => (this.form[curr.prop] = v)}
                data={[
                  { label: '激活', value: true },
                  { label: '暂停', value: false },
                ]}
              />
            )
          } else {
            return (
              <el-switch
                value={this.form[curr.prop]}
                on-change={(v) => (this.form[curr.prop] = v)}
              ></el-switch>
            )
          }
        },
        number: (curr) => (
          <el-input
            type="number"
            value={this.form[item.prop]}
            on-input={(v) => (this.form[item.prop] = v)}
          >
            {item.prepend && <template slot="prepend">{item.prepend}</template>}
            {item.append && <template slot="append">{item.append}</template>}
          </el-input>
        ),
        array: (curr) => {
          if (curr?.items['x-i18n']) {
            return this.generateSelect(curr)
          }
        },
        AttachmentForm: (curr) => (
          <SpUpload
            value={this.form[curr.prop]}
            on-change={(v) => (this.form[curr.prop] = v)}
          />
        ),
      }
      const tablePropKeys = Object.keys(tablePropMaps)
      const propKeys = Object.keys(propMaps)
      const typeKeys = Object.keys(typeMaps)

      if (propKeys.includes(item.prop)) {
        return propMaps[item.prop](item)
      } else if (typeKeys.includes(item.type)) {
        return typeMaps[item.type](item)
      } else if (this.customSchema && tablePropKeys.includes(item.prop)) {
        return tablePropMaps[item.prop](item)
      } else {
        if (item['x-i18n']) {
          return this.generateSelect(item)
        } else {
          return (
            <el-input
              value={this.form[item.prop]}
              on-input={(v) => (this.form[item.prop] = v)}
            >
              {item.prepend && (
                <template slot="prepend">{item.prepend}</template>
              )}
              {item.append && <template slot="append">{item.append}</template>}
            </el-input>
          )
        }
      }
    },
  },

  render() {
    return (
      <div
        class={this.formProps?.inline ? 'sp-form sp-inline-form' : 'sp-form'}
      >
        <el-form
          ref="spForm"
          size="small"
          rules={this.rules}
          on-change={(v) => {
            this.form = v
          }}
          labelWidth={this.customSchema ? '0' : '10em'}
          v-loading={this.isLoading}
          {...{ props: { model: this.form, ...this.formProps } }}
        >
          {this.schema &&
            this.schema.map((item) => {
              console.log(!item.renderIf || item.renderIf(this.form))
              if (!item.renderIf || item.renderIf(this.form)) {
                return (
                  <el-form-item
                    error={
                      this.errors[item.prop]
                        ? this.errors[item.prop].join(', ')
                        : undefined
                    }
                    class="sp-form-item"
                    label={
                      typeof item.title === 'function'
                        ? item.title(this.form)
                        : item.title
                    }
                    prop={item.prop}
                    key={item.prop}
                  >
                    {this.$scopedSlots[item.prop]
                      ? this.$scopedSlots[item.prop](this.form)
                      : this.renderFormItem(item)}

                    {item.tips && (
                      <div class="tips input-tips">{isFunction(item.tips) ? item.tips(this.form) : item.tips}</div>
                    )}
                  </el-form-item>
                )
              }
            })}

          {this.tips && (
            <el-form-item>
              <div class="tips">{this.tips}</div>
            </el-form-item>
          )}
        </el-form>
        {this.$scopedSlots.__extra && this.$scopedSlots.__extra(this.form)}
        {!this.readOnly && this.showSubmitBtn && (
          <div class="submit-bts">
            <el-button
              loading={this.submitLoading}
              size="small"
              type="primary"
              onClick={this.handleSubmit}
            >
              提交
            </el-button>
          </div>
        )}
      </div>
    )
  },
}
</script>

<style lang='scss'>
.sp-form {
  &.sp-inline-form {
    .el-input__inner {
      width: 160px;
    }
  }

  .sp-form-item {
    &.hide {
      display: none;
    }

    &.show {
      display: block;
    }
  }

  .submit-bts {
    margin-top: 40px;
    font-size: 14px;
    padding-left: 10em;
  }

  .tips {
    font-size: 12px;
    margin-bottom: 15px;
    color: rgba(#000, 0.25);
    line-height: 1.666666667;

    &:before {
      content: '*';
    }

    &.input-tips {
      margin-top: 10px;
    }
  }
}
</style>
