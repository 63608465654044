<template>
  <div class='sp-upload'>
    <div v-for="(obj, i) in showImages" :key="obj.signedId">
      <div v-if="obj.contentType.indexOf('image/') === 0" class="show-img" :style="{backgroundImage: `url(${obj.url})`}">
        <div class='action' @click="handleRemove(i)"><i class='icon icon-delete'></i></div>
      </div>
      <div v-else-if="obj.contentType.indexOf('audio/') === 0">
        <audio :src="obj.url" controls>
        </audio>
        <div class='action' @click="handleRemove(i)"><i class='icon icon-delete'></i></div>
      </div>
      <div v-else-if="obj.contentType.indexOf('video/') === 0">
        <div class="show-img">
          <video height="100%" style="margin-left:-40%" :src="obj.url" muted autoplay loop>
          </video>
          <div class='action' @click="handleRemove(i)"><i class='icon icon-delete'></i></div>
        </div>
      </div>
    </div>
    <el-upload :show-file-list='false' multiple="multiple" :drag="true" action="" v-if="showAdd" :http-request="handleUpload" :accept="ACCEPTS[accept] || accept">
      <div class='box' v-loading='loading'>
        <div class='add-img'>
          <svg t="1627631969831" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
              p-id="13255" width="128" height="128">
            <path d="M456.76636665 456.76636665V70.13093706a55.23363335 55.23363335 0 1 1 110.4672667 0v386.63542959h386.63542959a55.23363335 55.23363335 0 1 1 0 110.4672667H567.23363335v386.63542959a55.23363335 55.23363335 0 1 1-110.4672667 0V567.23363335H70.13093706a55.23363335 55.23363335 0 1 1 0-110.4672667h386.63542959z"
                  p-id="13256" fill='#8c939d'></path>
          </svg>
        </div>
      </div>
    </el-upload>
  </div>
</template>

<script>
import { DirectUpload } from '@rails/activestorage'
import { isArray, isEmpty } from 'lodash'
import { API } from '@/services/brand'

const directUpload = (upload) =>
  new Promise((resolve, reject) => {
    upload.create((error, res) => {
      if (error) {
        reject(error)
      } else {
        resolve(res.signed_id)
      }
    })
  })

export default {
  name: 'SpUpload',
  props: {
    value: null,
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: 'image'
    }
  },

  model: {
    prop: 'value',
    event: 'change',
  },

  data() {
    return {
      ACCEPTS: {
        image: '.jpg, .jpeg, .png, .gif, .JPG, .JPEG, .PNG, .GIF',
        video: '.mp4',
      },
      loading: false,
      showImages: isArray(this.value) ? this.value : [],
    }
  },

  computed: {
    showAdd() {
      return this.multiple ? true : this.showImages.length !== 1
    },
  },

  watch: {
    value(v) {
      if (isArray(v)) {
        this.showImages = v
      } else {
        if (isEmpty(v) === false){
          this.showImages = [v]
        } else {
          this.showImages = []
        }
      }
    },
  },

  methods: {
    async handleUpload(e) {
      this.loading = true
      // const url = 'http://api-test.zishipower.com/api/direct_uploads'
      const url = process.env.DIRECT_UPLOAD_URL
      const file = e.file

      const upload = new DirectUpload(file, url)

      const signedId = await directUpload(upload)
      const attachment = await API.attachmentShow(signedId)
      
      const result = {
        signedId,
        contentType: attachment.contentType,
        url: attachment.url,
      }

      this.showImages.push(result)
      this.loading = false

      if (this.multiple) {
        this.$emit('change', this.showImages)
      } else {
        const item = this.showImages.length ? this.showImages[0] : null
        this.$emit('change', item)
      }
    },

    handleRemove(i) {
      if (this.multiple) {
        this.showImages.splice(i, 1)
        this.$emit('change', this.showImages)
      } else {
        this.showImages = []
        this.$emit('change', {})
      }
    },
  },
}
</script>

<style lang='scss'>
.sp-upload {
  display: flex;
  flex-wrap: wrap;

  .el-upload-dragger {
    width: 120px;
    height: 120px;
  }

  .box {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: 20px;
      height: 20px;
      opacity: 0.7;
    }
  }

  .show-img {
    width: 120px;
    height: 120px;
    background: no-repeat center center / cover;
    border-radius: 6px;
    background-color: #fff;
    border: 1px dashed #d9d9d9;
    margin-right: 8px;
    margin-bottom: 8px;
    position: relative;
    overflow: hidden;

    &:hover {
      .action {
        visibility: visible;
      }
    }

    .action {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      align-items: center;
      justify-content: center;
      display: flex;
      visibility: hidden;
      cursor: pointer;
      transition: background-color 0.25s ease-out;

      &:hover {
        background-color: #eee;
      }
    }
  }
}
</style>
